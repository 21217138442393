<template>
  <div class="fb-body fb-signin-body fb-bodytext" >
    
    <h1 class="fb-signin-title">{{ $t('Admin.App.title') }}</h1>
    <h3>{{ $t('Admin.SignIn.title') }}</h3>
      <p>{{ $t("Admin.SignIn.subTitle") }}</p>

      <fbx-button
        theme="primary"
        button-text="Sign In"
        @clicked="signInClicked"
        minWidth="300px" />
  </div>
</template>

<script>
import auth from "../../auth.js"
import fbxButton from "../../components/fbx-button.vue"
export default {
  components: { fbxButton },
  data: () => ({
    account: {},
    authorized: false,
    bgimage: "url(../yardback.png)"
  }),
  async created() {
    //1 see if we just got returned from ad signin. There will be a hash in the route
    if (this.$route.hash) {
      this.setLoading(true)
      this.signInClicked()
    } else {
      //could have the user lets try and get a silent token
      const user = auth.getUser()

      if (user?.authorized) {
        this.$router.push({ name: "Home" })
      } else {
        console.warn("Can't autosign you in, you don't have an account saved, Click Signin")
        this.setLoading(false)
      }
    }
  },
  methods: {
    async signInClicked() {
      this.setLoading(true)
      let stoke = await auth.giveMeAuthTokenOrRedirect()
      if (stoke.authorized == false && stoke.accessToken) {
        alert("You're not authorized to view this. Please contact an Administrator.")
        auth.logout()
        this.setLoading(false)
      } else if (stoke.authorized && stoke.accessToken && stoke.safeToCallAPI) {
        this.$router.push({ name: "Home" })
      } else {
        auth.logout()
      }
    },
  },
}
</script>
