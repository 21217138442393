import axios from 'axios';
import store from "@/store"
import { ERROR_MESSAGES } from '@/shared/helpers';

function requestErrorHandler(err) {
    if (err?.response?.status >= 500) {
        store.dispatch("setError", ERROR_MESSAGES.CANNOT_COMPLETE_REQUEST);
    }

    throw err;
}

axios.interceptors.response.use(success => success, requestErrorHandler);

export default axios;