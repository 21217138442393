import associateQrToTrailer from "./associateQrToTrailer"
import getInstallTrailer from "./getInstallTrailer"
import getQrAndCompaniesByTrailerId from "./getQrAndCompaniesByTrailerId"
import getShopInstallTrailer from "./getShopInstallTrailer"
import getTrailerbyQrId from "./getTrailerbyQrId"
import getUserGroups from "./getUserGroups"
import postTrailerNotRegistered from "./postTrailerNotRegistered"
import updateLogosInstalledOnTrailer from "./updateLogosInstalledOnTrailer"
import removeQrsByTrailerId from "./removeQrsByTrailerId";
import saveImei from "./saveImei";

export default {
  associateQrToTrailer,
  getInstallTrailer,
  getQrAndCompaniesByTrailerId,
  getShopInstallTrailer,
  getTrailerbyQrId,
  getUserGroups,
  postTrailerNotRegistered,
  updateLogosInstalledOnTrailer,
  removeQrsByTrailerId,
  saveImei
}