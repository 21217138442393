<template>
    <div class="installation-container">
        <div class="exit-instructions" @click="goBack">
          <font-awesome-icon :icon="['fas', 'xmark']" />
        </div>

        <h2 class="installation-header bold">
            {{ $t("InstallationInstructions.title.title") }}
        </h2>

        <h4 class="installation-subheader bold">
            {{ $t("InstallationInstructions.title.subtitle") }}
        </h4>

        <ul>
            <li>
                {{ $t("InstallationInstructions.general.line1") }}
            </li>

            <li>
                {{ $t("InstallationInstructions.general.line2") }}
            </li>
        </ul>

        <div class="installation-navbox">
            <a href="#logo" class="installation-navbox-header">
                {{ $t("InstallationInstructions.nav.logo") }}
            </a>

            <div class="installation-navbox-subsection">
                <img class="installation-fbx-logo" src="../../assets/installation/fbx-logo.png" />

                <div class="installation-buttons-container">
                    <a class="installation-nav-button" href="#logo-front">{{ $t("InstallationInstructions.nav.front") }}</a>
                    <div class="installation-navbox-button-divider"></div>
                    <a class="installation-nav-button" href="#logo-side">{{ $t("InstallationInstructions.nav.side") }}</a>
                    <div class="installation-navbox-button-divider"></div>
                    <a class="installation-nav-button" href="#logo-rear">{{ $t("InstallationInstructions.nav.rear") }}</a>
                </div>
            </div>
        </div>

        <div class="installation-navbox">
            <a href="#qr" class="installation-navbox-header">
                {{ $t("InstallationInstructions.nav.qr") }}
            </a>

            <div class="installation-navbox-subsection">
                <img class="installation-fbx-qr" src="../../assets/installation/fbx-qr.svg" />

                <div class="installation-buttons-container">
                    <a class="installation-nav-button" href="#qr-front">{{ $t("InstallationInstructions.nav.front") }}</a>
                    <div class="installation-navbox-button-divider"></div>
                    <a class="installation-nav-button" href="#qr-side">{{ $t("InstallationInstructions.nav.side") }}</a>
                    <div class="installation-navbox-button-divider"></div>
                    <a class="installation-nav-button" href="#qr-rear">{{ $t("InstallationInstructions.nav.rear") }}</a>
                </div>
            </div>
        </div>

        <a id="logo" class="not-a-link">
            <h4 class="installation-subheader bold">
                {{ $t("InstallationInstructions.logoGeneral.title") }}
            </h4>
        </a>

        <ol class="installation-ordered-list">
            <li>
                {{ $t("InstallationInstructions.logoGeneral.line1") }}
            </li>

            <li>
                {{ $t("InstallationInstructions.logoGeneral.line2") }}
            </li>

            <li>
                {{ $t("InstallationInstructions.logoGeneral.line3") }}
            </li>
        </ol>

        <i18n-t tag="p" keypath="InstallationInstructions.logoGeneral.paragraph1">
            <a href="tel:+16026066450">{{ $t("InstallationInstructions.misc.phone") }}</a>
            <a href="mailto:FreightBox@knighttrans.com">{{ $t("InstallationInstructions.misc.email") }}</a>
        </i18n-t>

        <br />

        <a id="logo-front" class="not-a-link">
            <h5 class="installation-section-header bold">
                {{ $t("InstallationInstructions.logoFront.title") }}
            </h5>
        </a>

        <ol>
            <li>
                {{ $t("InstallationInstructions.logoFront.line1") }}
                <br />
                <img class="installation-image-in-list" src="../../assets/installation/logofront.png" />
            </li>

            <li>
                {{ $t("InstallationInstructions.logoFront.line2") }}
                <br />
                <img class="installation-image-in-list" src="../../assets/installation/logofront2.png" />
            </li>

            <li>
                {{ $t("InstallationInstructions.logoFront.line3") }}
                <br />
                <img class="installation-image-in-list" src="../../assets/installation/logofront3.png" />
            </li>
        </ol>
        
        <p>
            {{ $t("InstallationInstructions.logoFront.paragraph1") }}
        </p>

        <i18n-t tag="p" keypath="InstallationInstructions.logoFront.paragraph2">
            <a href="tel:+16026066450">{{ $t("InstallationInstructions.misc.phone") }}</a>
            <a href="mailto:FreightBox@knighttrans.com">{{ $t("InstallationInstructions.misc.email") }}</a> 
        </i18n-t>

        <a id="logo-side" class="not-a-link">
            <h5 class="installation-section-header">
                {{ $t("InstallationInstructions.logoSide.title") }}
            </h5>
        </a>

        <ol>
            <li>
                <p>
                    {{ $t("InstallationInstructions.logoSide.line1") }}
                </p>

                <p>
                    {{ $t("InstallationInstructions.logoSide.line2") }}
                </p>

                <p>
                    {{ $t("InstallationInstructions.logoSide.line3") }}
                </p>

                <p>
                    {{ $t("InstallationInstructions.logoSide.line4") }}
                </p>

                <img class="installation-image-in-list" src="../../assets/installation/logoside.png" />
            </li>

            <i18n-t tag="li" keypath="InstallationInstructions.logoSide.line5">
                <a href="tel:+16026066450">{{ $t("InstallationInstructions.misc.phone") }}</a>
                <a href="mailto:FreightBox@knighttrans.com">{{ $t("InstallationInstructions.misc.email") }}</a>
            </i18n-t>
        </ol>
            
        <a id="logo-rear" class="not-a-link">
            <h5 class="installation-section-header bold">
                {{ $t("InstallationInstructions.logoRear.title") }}
            </h5>
        </a>

        <ol>
            <li>
                <p>
                    {{ $t("InstallationInstructions.logoRear.line1") }}
                </p>
            
                <p class="center-bullet">
                    {{ $t("InstallationInstructions.logoRear.line2") }}
                </p>

                <img class="installation-image-in-list" src="../../assets/installation/logorear.png" />

                <p class="center-bullet">
                    {{ $t("InstallationInstructions.logoRear.line3") }}
                </p>

                <img class="installation-image-in-list" src="../../assets/installation/logorear2.png" />
            </li>

            <li>
                {{ $t("InstallationInstructions.logoRear.line4") }}
                <br />
                <img class="installation-image-in-list" src="../../assets/installation/logorear3.png" />
            </li>
        </ol>

        <i18n-t tag="p" keypath="InstallationInstructions.logoRear.paragraph1">
            <a href="tel:+16026066450">{{ $t("InstallationInstructions.misc.phone") }}</a>
            <a href="mailto:FreightBox@knighttrans.com">{{ $t("InstallationInstructions.misc.email") }}</a>
        </i18n-t>

        <a id="qr" class="not-a-link">
            <h3 class="installation-subheader bold">
                {{ $t("InstallationInstructions.qrGeneral.title") }}
            </h3>
        </a>

        <ol>
            <li>
                {{ $t("InstallationInstructions.qrGeneral.line1") }}
            </li>

            <li>
                {{ $t("InstallationInstructions.qrGeneral.line2") }}
            </li>

            <li>
                {{ $t("InstallationInstructions.qrGeneral.line3") }}
            </li>

            <li>
                {{ $t("InstallationInstructions.qrGeneral.line4") }}
            </li>
        </ol>

        <i18n-t tag="p" keypath="InstallationInstructions.qrGeneral.paragraph1">
            <a href="tel:+16026066450">{{ $t("InstallationInstructions.misc.phone") }}</a>
            <a href="mailto:FreightBox@knighttrans.com">{{ $t("InstallationInstructions.misc.email") }}</a>
        </i18n-t>
        
        <a id="qr-front" class="not-a-link">
            <h5 class="installation-section-header bold">
                {{ $t("InstallationInstructions.qrFront.title") }}
            </h5>
        </a>

        <ol>
            <li>
                {{ $t("InstallationInstructions.qrFront.line1") }}
            </li>
        </ol>

        <img class="installation-image" src="../../assets/installation/qrcodefront.png" />
        <br />
        <img class="installation-image" src="../../assets/installation/qrcodefront2.png" />

        <a id="qr-side" class="not-a-link">
            <h5 class="installation-section-header bold">
                {{ $t("InstallationInstructions.qrSide.title") }}
            </h5>
        </a>

        <ol>
            <li>
                {{ $t("InstallationInstructions.qrSide.line1") }}
            </li>
        </ol>

        <img class="installation-image" src="../../assets/installation/qrside.png" />
        <br />
        <img class="installation-image" src="../../assets/installation/qrside2.png" />
        <br />
        <img class="installation-image" src="../../assets/installation/qrside3.png" />

        <a id="qr-rear" class="not-a-link">
            <h5 class="installation-section-header">
                {{ $t("InstallationInstructions.qrRear.title") }}
            </h5>
        </a>

        <ol>
            <li>
                {{ $t("InstallationInstructions.qrRear.line1") }}
                <br />
                <img class="installation-image-in-list" src="../../assets/installation/qrrear.png" />
                <br />
                <img class="installation-image-in-list" src="../../assets/installation/qrrear2.png" />
            </li>

            <li>
                {{ $t("InstallationInstructions.qrRear.line2") }}
                <br />
                <img class="installation-image-in-list" src="../../assets/installation/qrrear3.png" />
            </li>
        </ol>
    </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push({ path: window.history.state.back })
    }
  }
}
</script>

<style>
.exit-instructions {
  position: absolute;
  right: .5em;
  top: .5em;
  color: #c7c6c4;
  font-size: 1.5em;
  cursor: pointer;
  margin: 5px;
}

.installation-container {
    max-width: min(384px, 90vw);
    text-align: left;
    color: #615F5A;
}

.installation-header {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: -1rem;
}

.installation-navbox {
    width: min(384px, 90vw);
    max-width: calc(100vw - 3.875rem);
    height: 202px;
    border: 2px solid #51504f;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.installation-navbox-header {
    font-size: 1.5rem;
    text-align: center;
}

.installation-navbox-subsection {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.installation-fbx-logo {
    width: 75px;
    margin: auto 40px auto 0;
}

.installation-fbx-qr {
    width: 100px;
    margin: auto 40px auto 0;
}

.installation-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.installation-nav-button {
    font-size: 1.3rem;
    color: #fba835;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.installation-navbox-button-divider {
    margin: 0;
    background-color: #555555;
    height: 2px;
}

.not-a-link:active {
    box-shadow: none;
}

.installation-section-header {
    text-align: center;
    margin-top: 2rem;
}

.installation-subheader {
    text-align: center;
    margin-top: 2rem;
}

.installation-image-in-list {
    width: min(calc(384px - 4rem), calc(90vw - 4rem));
    margin: 1rem 0 1rem 0;
}

.installation-image {
    width: min(calc(384px - 4rem), calc(90vw - 4rem));
    margin: 1rem 2rem 1rem 2rem;
}

.center-bullet {
  width: min(calc(384px - 4rem), calc(90vw - 4rem));
  text-align: center;
  margin: 1rem 0 0 0;
}
</style>