<template>
  <div id="overlay">
    <img
      class="loading-spinner"
      src="..\assets\loading-wheel.svg"
      alt="loading">
  </div>
</template>

<style lang="scss" scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding-top: 275px;
  z-index: 2;
  background: $uix-shade-400;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.loading-spinner {
  width: 20vw;
  height: auto;
  max-width: 150px;
  min-width: 100px;
      animation: rotation 2s linear infinite;
      -webkit-animation: rotation 2s linear infinite;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
