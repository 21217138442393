import axios from "@/libraries/axios/axiosConfig";

export default async function (carrierId, trailerId, trailerCompany, location, mobileNumber, qid) {
  return await axios({
    method: "post",
    url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/trailerNotRegistered`,
    headers: { "X-TacLite-AppToken": `${process.env.VUE_APP_TACLITE_APPTOKEN_VALUE}` },
    data: {
      carrierId: carrierId,
      trailerId: trailerId,
      trailerCompany: trailerCompany,
      location: location,
      mobileNumber: mobileNumber,
      qrId: qid
    }
  }).then(r => r.data)
}