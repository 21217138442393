module.exports = {
  "auth": {
    "clientId": process.env.VUE_APP_AZURE_AD_CLIENT_ID,
    "authority": "https://login.microsoftonline.com/0f298105-68e6-4cbe-844d-69ffc1806495",
    "redirectUri": `${process.env.VUE_APP_FBA_SHOP_ROOT_URL}signin`
  },
  "cache": {
    "cacheLocation": "localStorage", 
    "storeAuthStateInCookie": true 
  },
  "apiAppId": process.env.VUE_APP_AZURE_AD_API_CLIENT_ID,
  "scope":["openid", "profile"],
  "apiScope": [`api://${process.env.VUE_APP_AZURE_AD_API_CLIENT_ID}/access_as_user`], 
  "necessaryRole": `${process.env.VUE_APP_AZURE_AD_AUTHORIZED_USER_ROLES}`.split(',')
}
