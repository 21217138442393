import authConfig from "./authconfig"
import * as msal from "@azure/msal-browser"

const necessaryRoles = authConfig.necessaryRole //This is the thing we use to validate in the browser
const msalObj = new msal.PublicClientApplication(authConfig)

//Just checking that SPA has access to the role.
//if the SPA and API have roles named differently this won't work.
function authorize(account) {
  if (!account) {
    console.warn("no data saved for user")
    return false
  }

  if (!account?.idTokenClaims?.roles) {
    console.warn("No roles in token claims, Is user part of the necessary role?")
    account.idTokenClaims = null
    return false
  }

  const now = new Date()
  if (account?.idTokenClaims?.exp && now < new Date(account.idTokenClaims.exp) * 1000) {
    const roles = account.idTokenClaims.roles
    return roles.findIndex((role) => necessaryRoles.includes(role)) !== -1
  } else {
    return false
  }

}

//If there is no account data, you need to set the active accout.
//msalObj.setActiveAccount(account) look at setAccountData function
async function getAccessTokenSilent() {
  const account = msalObj.getActiveAccount()

  if (account) {
    const apiAccessRequest = {
      scopes: authConfig.apiScope, //This is the scope for the api. not the JS client
      account: account, //This is the logged in user.
    }
    try {
      let silentTokenResponse = await msalObj.acquireTokenSilent(apiAccessRequest)
      return silentTokenResponse.accessToken
    } catch (error) {
      console.log(error)
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        await msalObj.acquireTokenRedirect(apiAccessRequest)
        return
      } else {
        await msalObj.acquireTokenRedirect(apiAccessRequest)
        throw error
      }
    }
  } else {
    console.warn("MSAL: no account")
  }
}

function setAccountData(account) {
  const accountDataToSave = {
    homeAccountId: account.homeAccountId,
    name: account.name,
    authorized: authorize(account), //last ditch check
    username: account.username,
  }

  msalObj.setActiveAccount(account)

  return accountDataToSave
}

async function handleMsalResponse(response) {
  //It is up to us to figure out what we're being returned
  //Id Token comes before Access token
  let returnObject = {
    safeToCallAPI: false,
    account: {},
    idToken: "",
    accessToken: "",
    authorized: false,
  }

  //First we want to know if we got a response from the redirect or if we have to build everything oursleves
  if (response?.idToken) {
    //Back from 1st redirect, we have an access token
    returnObject.account = setAccountData(response.account) //set the account to the id token
    returnObject.idToken = response.idToken
    returnObject.authorized = authorize(response.account)
    returnObject.accessToken = await getAccessTokenSilent()
  } else if (response?.accessToken && response?.aud == authConfig.apiAppId) {
    //We have an accessToken for our API
    returnObject.accessToken = response.accessToken
    returnObject.account = setAccountData(msalObj.getActiveAccount())
    returnObject.authorized = authorize(returnObject.account)
  } else if (response == null) {
    //try to set an account or login then do the stuff
    returnObject.account = msalObj.getActiveAccount()

    if (!returnObject.account) {
      let potentialAccounts = msalObj.getAllAccounts()
      if (potentialAccounts.length === 0) {
        console.warn("time to log you in, no accounts found in cache to pull from.")
        await msalObj.loginRedirect()
      } else if (potentialAccounts.length > 1) {
        await msalObj.loginRedirect({prompt: "select_account"})
      } else {
        returnObject.account = potentialAccounts[0]
        setAccountData(potentialAccounts[0])
      }
    }

    returnObject.accessToken = await getAccessTokenSilent()
    returnObject.authorized = authorize(returnObject.account)
  }

  if (returnObject.accessToken && returnObject.authorized) {
    returnObject.safeToCallAPI = true
  }
  return returnObject
}

function getUser() {
  let user = msalObj.getActiveAccount() || {}
  if (user) {
    user.authorized = authorize(user)
  } else {
    user.authorized = false
  }
  return user
}

async function logout() {
  const currentUser = getUser()
  const currentAccount = msalObj.getAccountByHomeId(currentUser.homeAccountId)

  await msalObj.logoutRedirect({ account: currentAccount})
}

async function giveMeAuthTokenOrRedirect() {
  return msalObj.handleRedirectPromise().then(handleMsalResponse)
}

export default {
  giveMeAuthTokenOrRedirect,
  getAccessTokenSilent,
  logout,
  getUser
}
