<template>
  <button v-bind:style="{ minWidth: minWidth}"
    type="button"
    :class="buttonClasses"
    @click="$emit('clicked')">
    <font-awesome-icon v-if="icon" :icon="icon" />
    <span v-else v-html="buttonText"></span>
  </button>
</template>

<script>
import BaseStyleControl from "../styles/baseStyleControl"
const buttonThemes = BaseStyleControl.buttonThemes

export default {
  name: "FbxButton",
  props: {
    minWidth:{
      type: String,
      default: "10px"
    },
    buttonText: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Array,
      default: null
    },
    overrideClass: {
      type: String,
      default: ""
    },
    theme: {
      type: String,
      default: "primary",
      validator(theme) {
        if (!buttonThemes.includes(theme)) {
          console.warn(`fbx-button has invalid theme - ${theme}. valid themes are ${buttonThemes}`)
          return false
        } else {
          return true
        }
      },
    },
  },
  emits: ["clicked"],
  setup: (props) => {},
  data: () => {
    return {}
  },
  computed: {
    buttonClasses() {
      let retVal = '';

      if (this.theme == "link") {
        retVal = "btn btn-link"
      } else {
        if (this.overrideClass) {
          retVal = this.overrideClass
        } else {
          retVal = BaseStyleControl.getButtonClassesForTheme(this.theme)
        }
      }

      if (this.disabled === true) {
        retVal += ' disabled';
      }

      return retVal;
    },
  },
  watch: {},
  created: async () => {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.wide {
  width: 100%;
}
</style>
