import axios from "@/libraries/axios/axiosConfig";

export default async function(qrId) {
  return await axios({
    method: "get",
    url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/getByQrId/${qrId}`,
    headers: {
      "X-TacLite-AppToken": "69A0D5B5-9F51-4720-A914-2CE41D6017B8"
    }
  }).then(r => r.data)
}
