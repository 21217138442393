<template>
  <div>
    <div class="fb-container body">
      <div class="fb-form">
        <div class="margin-bottom">
          <h1 class="fb-form-heading">{{ headerText }}</h1>
          <p class="fb-form-paragaraph">{{ subheaderText }}</p>
        </div>
        <p class="bold subheader-margin">{{ subheaderTextSecond }}</p>
        <p class="bold">{{ subheaderTextThird }}</p>
        <p v-if="hasErrorMessage" class="fb-form-paragraph">{{ errorMessage }}</p>
        <trailer-arial v-else :qrSideButtons="qrSideButtons" :isRemoveQr="isRemoveQr" :arialType="'text'" :backDisplay="backDisplay"
          :backId="backId" :driverDisplay="driverDisplay" :driverId="driverId" :frontDisplay="frontDisplay"
          :frontId="frontId" :passengerDisplay="passengerDisplay" :passengerId="passengerId"
          :templateClass="trailerArialClass" @trailer-clicked="trailerClicked">
        </trailer-arial>
        <fbx-button ref="fbxButton" :overrideClass="doneButtonClass"
          :class="{ 'disabled': !selectedSides.length > 0 }" :button-text="doneButtonText"
          @clicked="handleSubmitClick" />
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../auth.js";
import fbxButton from "../../components/fbx-button.vue";
import TrailerArial from '../../components/TrailerArial.vue';
import { find, findLast } from 'lodash';
import { mapGetters } from 'vuex';
import { ERROR_MESSAGES } from '@/shared/helpers';

export default {
  components: {
    TrailerArial,
    fbxButton
  },
  data: () => ({
    headerText: null,
    backId: null,
    backDisplay: '',
    driverId: null,
    driverDisplay: '',
    errorMessage: null,
    frontId: null,
    frontDisplay: '',
    passengerId: null,
    passengerDisplay: '',
    trailerArialClass: 'fb-trailer-side-selector',
    trailerId: null,
    installTrailer: null,
    doneButtonClass: 'btn btn-primary fb-form-button',
    doneButtonText: null,
    qrSideButtons: [
      { side: 'front', selected: false, qrId: null },
      { side: 'driver', selected: false, qrId: null },
      { side: 'passenger', selected: false, qrId: null },
      { side: 'back', selected: false, qrId: null },
    ],
    isRemoveQr: true
  }),
  async created() {
    this.setAdminLoading(true, this.onSetLoadingComplete);

    const trailerInfo = this.$store.getters.getCurrentTrailerInfo;
    if (!trailerInfo) {
      this.$router.push({ name: "TrailerLookup" });
      return;
    }
    this.company = trailerInfo.company;
    this.trailerId = trailerInfo.trailerId;
    this.headerText = 'Remove QR Code';
    this.subheaderText = `${this.titleCase(this.company)} Trailer #${this.trailerId}`;
    this.subheaderTextSecond = 'Please select a side(s) to remove a QR code.';
    this.subheaderTextThird = 'Select all sides, if applicable.'
    this.doneButtonText = `<span>${this.$t('Admin.Trailer.doneButton')}</span>`;

    // Query for list of QR Codes already associated with the trailer
    const qrs = await this.$store.dispatch("getQrAndCompaniesByTrailerId", {
      id: trailerInfo.trailerId
    });
    if (qrs.isValid === false) {
      this.errorMessage = 'Unable to access trailer information. Please try again later.';
    } else if (qrs[trailerInfo.company]) {
      this.showQrCodes(qrs[trailerInfo.company]);
    }
    this.setAdminLoading(false, this.onSetLoadingComplete);
  },
  methods: {
    showQrCodes(trailerQrs) {
      ['back', 'driver', 'front', 'passenger'].forEach(side => {
        const qr = findLast(trailerQrs, { qrInstallLocation: side }) || find(trailerQrs, { qrInstallLocation: 'default' });
        this[`${side}Display`] = qr?.qrId || null;
        this[`${side}Id`] = qr?.qrId || null;
      });
    },
    trailerClicked(side, qrId) {
      const index = this.qrSideButtons.findIndex(item => item.side === side);
      this.qrSideButtons[index].selected = !this.qrSideButtons[index].selected;
      this.qrSideButtons[index].qrId = qrId;
    },
    async handleSubmitClick() {
      this.setAdminLoading(true, this.onSubmitComplete);

      try {
        const response = await this.$store.dispatch("removeQrsByTrailerId", {
          token: await auth.getAccessTokenSilent(),
          trailerId: this.currentTrailerInfo?.trailerId,
          trailerCompany: this.currentTrailerInfo?.company,
          qrCodes: this.selectedSides,
        });
        if (response?.isSuccessful) {
          this.$store.commit("clearCurrentTrailerInfo");
          this.$router.push({ name: "Home" });
          this.setAdminLoading(false, this.onSubmitComplete);
          return;
        }
      } catch (ex) {
        console.warn(ex);
        alert("Something went wrong, please try again.");
      }

      this.$store.dispatch("setError", ERROR_MESSAGES.CANNOT_COMPLETE_REQUEST)
      this.setAdminLoading(false, this.onSubmitComplete);
    }
  },
  computed: {
    ...mapGetters({ currentTrailerInfo: 'getCurrentTrailerInfo' }),
    hasErrorMessage() {
      return this.errorMessage !== null;
    },
    selectedSides() {
      return this.qrSideButtons
        .filter(qr => qr.selected)
        .map(qr => ({ QrInstallLocation: qr.side, QrId: qr.qrId }));
    }
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.margin-bottom {
  margin-bottom: 40px;
}

.subheader-margin {
  margin-bottom : 0px;
}
</style>
