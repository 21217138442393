import axios from "@/libraries/axios/axiosConfig";

export default async function (trailerId, trailerCompany, qrCodes, token) {
  return await axios({
    method: "post",
    url: `${process.env.VUE_APP_EXT_API_URL}api/admin/qr/disassociateTrailerFromQr`,
    headers: { "X-TacLite-AppToken": `${process.env.VUE_APP_TACLITE_APPTOKEN_VALUE}`, Authorization: `Bearer ${token}` },
    data: {
      trailerId: trailerId,
      trailerCompany: trailerCompany,
      qrCodes: qrCodes
    }
  }).then(r => r.data)
}