const globalConstants = {
  reasonCodes: {
    cannotSaveQrToTrailer: 450,
    trailerAlreadyHasQr: 451,
    invalidQr: 452,
    qrAlreaduRegistered: 453,
    invalidPhoneNumber: 460,
    configurationError: 461,
    invalidMfaCode: 462,
    loadNotFound: 471,
    loadAlreadyCompleted: 472,
    trailerNotFound: 480,
    scannedIdNotFound: 481,
    scannedIdAlreadyAssigned: 482,
    invalidTrailerType: 484,
    carrierNotFound: 490,
    carrierNotActive: 491,
    driverAlreadyRegistered: 492,
  },
  locale: {
    english: "en",
    spanish: "es",
    pirate: "ps",
  },
  trailer: {
    loadedStatus: 0,
    emptyStatus: 1,

    statuses: {
      TRAILER_SERVICE_STATUS: "S",
    },
  
    types: {
      DEDICATED_ROLL_DOOR: "Dedicated Roll Door",
      DEDICATED_VAN: "Dedicated Van",
      HIGH_CUBE: "High Cube",
      ROLL_DOOR: "Roll Door",
      STANDARD: "Standard",
    },
  },
  keyboard: {
    key: {
      backspace: "Backspace",
      digits: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
    },
    code: {
      backspace: "Backspace",
      digits: ["Digit1", "Digit2", "Digit3", "Digit4", "Digit5", "Digit6", "Digit7", "Digit8", "Digit9", "Digit0"]
    },
  },
}

export default globalConstants
