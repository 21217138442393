<template>
  <footer
    v-if="!loading"
    class="fb-footer">
    <div class="container-fluid fb-container-fluid">
      <div class="fb-footer-links">
        <!-- Uncomment once we have actual verbiage for these items -->
        <!-- <fbx-button
          class="footer-item"
          theme="link"
          :button-text="$t('Footer.about')"
          @clicked="$router.push('/about')" />

        <fbx-button
          class="footer-item"
          theme="link"
          :button-text="$t('Footer.support')"
          @clicked="$router.push('/support')" />

        <fbx-button
          class="footer-item"
          theme="link"
          :button-text="$t('Footer.terms')"
          @clicked="$router.push('/termsOfUse')" /> -->

        <fbx-button
          data-test="footer-privacy-button"
          class="footer-item"
          theme="link"
          :button-text="$t('Footer.privacy')"
          @clicked="privacyClick()" />

        <fbx-button
          data-test="footer-language-button"
          class="footer-item"
          theme="link"
          :button-text="language"
          @clicked="onLocaleClick()" />
      </div>
      <copyright />
    </div>
  </footer>
</template>

<script>
import globalConstants from "../plugins/globalConstants"
import FbxButton from "./fbx-button.vue"
import Copyright from "./Copyright.vue"

export default {
  
  components: { FbxButton, Copyright },
  data: () => ({
    currentLocale: null,
  }),
  computed: {
    language() {
      if (this.currentLocale === globalConstants.locale.english) {
        return this.$t("Footer.locale.spanish")
      } else {
        return this.$t("Footer.locale.english")
      }
    },
    loading() {
      return this.$store.getters.getGlobalLoadingStatus
    },
  },
  created() {
    this.currentLocale = this.$store.getters.getCurrentLocale
    this.$i18n.locale = this.currentLocale
  },
  methods: {
    onLocaleClick() {
      if (this.currentLocale === globalConstants.locale.english) {
        this.currentLocale = globalConstants.locale.spanish
      } else {
        this.currentLocale = globalConstants.locale.english
      }
      this.$store.dispatch("saveCurrentLocale", this.currentLocale)
      this.$i18n.locale = this.currentLocale
    },
    privacyClick() {
      window.location.href = "https://privacy.knight-swift.com"
    },
  },
}
</script>

<style lang="scss" scoped>
.fb-footer-links,
.fb-container-fluid,
.fb-footer {
  align-content: center;
  align-items: center;
  display: flex;
  gap: 0;
  justify-content: stretch;
  margin: 0;
  position: relative;
}

.fb-footer-links {
  flex-flow: row wrap;
  margin: 0 auto 5px;
  padding: 0;

  .btn {
    border-radius: 13px;
    color: $uix-paragraph-text-color;
    height: 26px;
    margin: 0;
    min-height: 0 !important;
    padding: 1px 10px;
    position: relative;

    &:focus,
    &:hover {
      color: $uix-shade-black;
    }
  }
}

.fb-container-fluid {
  flex-flow: column nowrap;
  margin: 0 auto;
  max-width: $uix-app-max-width;
  min-height: $uix-footer-min-height;
  padding: 25px 15px 0;
  width: 100%;
}

.fb-footer {
  background-color: $uix-shade-alt-one;
  border-top: 5px solid $uix-navbar-border-color;
  flex-flow: column nowrap;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 0;
}
</style>
