<template>
  <div
    v-if="error"
    class="d-flex flex-row justify-content-center error-banner">
    <div class="d-flex flex-row justify-content-between content-wrapper">
        <div class="icon">
            <font-awesome-icon :icon="['fas', 'circle-info']" />
        </div>
        <div class="text-container">
            {{ error }}
        </div>
        <div class="x-out-container" @click="closeError">            
            <a>
                <font-awesome-icon :icon="['fas', 'xmark']" />
            </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorBanner",
  emits: ["closeError"],
  computed: {
    error() {
      return this.$store.getters.getError;
    },
  },
  methods: {
    closeError() {
      this.$store.dispatch("setError", null)
      this.$emit("closeError")
    },
  },
}
</script>

<style lang="scss" scoped>
.error-banner {
    background-color: $uix-color-error;
    color: #fff;
    width: 100%;
    position: absolute;
    top: 0px;
}

.content-wrapper {
    margin: 16px;
    width: 100%;
    max-width: 600px;
    line-height: 19px;

    .icon {
        align-self: center;
        margin-right: 12px;

        svg {
            height: 24px;
            width: 24px;
        }
    }

    .text-container {
        text-align: start;
        font-size: 14px;
        max-width: 500px;
    }

    .x-out-container {
        margin-left: 16px;

        svg {
            height: 18px;
            width: 18px;
        }
    }
}
</style>
