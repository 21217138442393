<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{this.headerText}}
                </h1>
                <p class="fb-form-paragraph">
                  {{ $t('Admin.Trailer.firstSubheaderText') }}
                </p>

                <p v-if="this.errorMessage != null" class="fb-form-paragraph">{{this.errorMessage}}</p>
                <trailer-arial
                    :arialType="'text'"
                    :backDisplay="backDisplay"
                    :backId="backId"
                    :driverDisplay="driverDisplay"
                    :driverId="driverId"
                    :frontDisplay="frontDisplay"
                    :frontId="frontId"
                    :passengerDisplay="passengerDisplay"
                    :passengerId="passengerId"
                    :templateClass="this.trailerArialClass"
                    @trailer-clicked="trailerClicked"
                    v-if="this.errorMessage == null">
                </trailer-arial>

                <div class="d-flex flex-column align-items-center logo-confirmation-container">
                  <p class="fb-form-paragraph">
                    {{ $t('Admin.Trailer.secondSubheaderText') }}
                  </p>
                  <div class="d-flex flex-column justify-content-between select-box" :class='{ "active-good" : logosInstalled, "active-bad" : logosInstalled === false }'>

                    <div class="logo-svg">
                      <img src="../../assets/freightbox-tall-logo.svg"/>
                    </div>

                    <div class="d-flex flex-row justify-content-center thumb-toggles">
                      <div class="btn-good" @click="confirmInstallLogos(true)">
                        <font-awesome-icon
                            :icon="['fas', 'thumbs-up']"
                        />
                      </div>

                      <div class="btn-bad" @click="confirmInstallLogos(false)">
                        <font-awesome-icon
                            :icon="['fas', 'thumbs-down']"
                        />
                      </div>
                    </div>
                  </div>

                </div>

                <fbx-button
                    ref="fbxButton"
                    :overrideClass="doneButtonClass"
                    :class="{'disabled' : !hasRecordedLogoInstallStatus }"
                    :button-text="doneButtonText"
                    @clicked="onDoneClicked" />
                <p class="fb-form-paragraph align-left">
                    {{$t('Admin.Trailer.disclaimerText')}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isNullOrUndefined } from '../../shared/helpers.js'
import auth from "../../auth.js"
import fbxButton from "../../components/fbx-button.vue"
import trailerArial from "../../components/TrailerArial.vue"
import { find, findLast } from 'lodash'
import { ERROR_MESSAGES } from '@/shared/helpers';

export default {
    components: {
        fbxButton,
        trailerArial
    },
    data: () => ({
        backId: null,
        backDisplay: '',
        driverId: null,
        driverDisplay: '',
        errorMessage: null,
        frontId: null,
        frontDisplay: '',
        headerText: null,
        passengerId: null,
        passengerDisplay: '',
        trailerArialClass: 'fb-trailer-side-selector',
        trailerId: null,
        installTrailer: null,
        doneButtonClass: 'btn btn-primary fb-form-button',
        doneButtonText: null,
    }),
    async created() {
        this.setLoading(false)
        this.setAdminLoading(true, this.onSetLoadingComplete)
        let trailerInfo = this.$store.getters.getCurrentTrailerInfo
        if (!trailerInfo) {
            this.$router.push({ name: "TrailerLookup" })
            return
        }
        this.doneButtonText = `<span>${this.$t('Admin.Trailer.doneButton')}</span>`


        this.trailerId = trailerInfo.trailerId
        this.headerText = `${this.titleCase(trailerInfo.company)} ${this.$t('Admin.Trailer.headerText')} #${this.trailerId}`

        // Query for list of QR Codes already associated with the trailer
        let qrs = await this.$store.dispatch("getQrAndCompaniesByTrailerId", {
            id: trailerInfo.trailerId
        })
        if (qrs.isValid === false) {
            this.errorMessage = 'Unable to access trailer information. Please try again later.'
        } else if (qrs[trailerInfo.company]) {
            let trailerQrs = qrs[trailerInfo.company]

            // If there are no QRs yet associated with the trailer, take the user directly to the scan page to establish the first QR code scanned as their "default"
            if (trailerQrs.length === 0) {
                this.setAdminLoading(false, this.onSetLoadingComplete)
                    this.$router.push({
                        name: "TrailerRegister",
                        params: {
                            side: "default",
                            trailerId: this.trailerId
                        }
                    })
                return
            }

            this.showQrCode(trailerQrs, 'back')
            this.showQrCode(trailerQrs, 'driver')
            this.showQrCode(trailerQrs, 'front')
            this.showQrCode(trailerQrs, 'passenger')
        }
        await this.getInstallTrailerLogoStatus()
        this.setAdminLoading(false, this.onSetLoadingComplete)
    },
    computed: {
      ...mapGetters({ logosInstalled: 'getLogosInstalled', currentTrailerInfo: 'getCurrentTrailerInfo', adminGlobalLoading: 'getAdminGlobalLoadingStatus' }),
      hasRecordedLogoInstallStatus() {
        return !isNullOrUndefined(this.logosInstalled)
      }
    },
    methods: {
        confirmInstallLogos(bool) {
          if (bool === this.logosInstalled) {
            this.$store.commit("setLogosInstalled", null)
            return;
          }

          this.$store.commit("setLogosInstalled", bool)
        },
        async getInstallTrailerLogoStatus() {
          const response = await this.$store.dispatch("getInstallTrailer", {
            trailerId: this.currentTrailerInfo?.trailerId,
            trailerCompany: this.currentTrailerInfo?.company,
            accessToken: await auth.getAccessTokenSilent()
          })

          if (response.installTrailer) {
            this.installTrailer = response.installTrailer

            const hasLogos = this.installTrailer?.logoInstallations[0]?.logosInstalled
            this.$store.commit("setLogosInstalled", hasLogos)
          } else {
            console.warn("Cound not retrieve Install Trailer", response)
          }
        },
        async onDoneClicked() {
          this.setAdminLoading(true, this.onSubmitComplete)

          try {
            let deviceDetails = await this.getDeviceDetails()

            const response = await this.$store.dispatch("updateLogosInstalledOnTrailer", {
              trailerId: this.currentTrailerInfo?.trailerId,
              trailerCompany: this.currentTrailerInfo?.company,
              logosInstalled: this.logosInstalled,
              browser: deviceDetails.browser,
              location: deviceDetails.location,
              device: deviceDetails.device,
              operatingSystem: deviceDetails.os,
              accessToken: await auth.getAccessTokenSilent()
            })

            if (response?.isSuccessful) {
              this.$store.commit("clearCurrentTrailerInfo")
              this.$router.push({ name: "TrailerLookup" })
              this.setAdminLoading(false, this.onSubmitComplete)
              return
            } 

          } catch(ex) {
            console.warn(ex)
          }

          this.installTrailer = null
          this.$store.dispatch("setError", ERROR_MESSAGES.CANNOT_COMPLETE_REQUEST);
          this.setAdminLoading(false, this.onSubmitComplete)
          alert("Something went wrong, please try again.")
        },
        onSetLoadingComplete(isLoading) {
            let loadingClass = ' loading'
            this.trailerArialClass = isLoading === true ?
                                     this.trailerArialClass + loadingClass :
                                     this.trailerArialClass.replace(loadingClass, '')
        },
        onSubmitComplete(isLoading) {
            let submitClass = ` ${this.$t('Admin.Trailer.doneButtonActionText')}`
            this.doneButtonClass = isLoading === true ?
                                     this.doneButtonClass + submitClass :
                                     this.doneButtonClass.replace(submitClass, '')
        },
        showQrCode(trailerQrs, side) {
            let qr = findLast(trailerQrs, { qrInstallLocation: side })
            let qrId = null
            if (qr) {
                qrId = qr.qrId
            }
            else {
                // Look for default
                let defaultQr = find(trailerQrs, { qrInstallLocation: 'default' })
                if (defaultQr) {
                    qrId = defaultQr.qrId
                }
            }

            this[`${side}Display`] = qrId
            this[`${side}Id`] = qrId
        },
        trailerClicked(side, qrId) {
                    this.$router.push({
                        name: "TrailerRegister",
                        params: {
                            side,
                            trailerId: this.trailerId
                        },
                        query: {
                            qrId: qrId ? qrId : ''
                        }
                    })
        }
    }
}
</script>

<style lang="scss" scoped>
.fb-form-heading {
  margin: 30px 0 45px;
}

.fb-form-paragraph {
    font-size: 1rem;
    font-weight: 600;

    > em {
        display: block
    }
}

.fb-form-button {
    margin: 30px 0 0;
    max-width: 388px !important;
    min-height: 58px;
    border-radius: 29px;
}

.uix-control {
    margin: 0 0 60px;
}

.fb-form-paragraph {

    &.align-left {
        margin: 20px 0 40px;
        text-align: left;
    }
}

.logo-confirmation-container {
  padding: 10px 0 30px 0;

  .select-box {
    max-width: 189px;
    width: 100%;
    height: 248px;
    border: 1px solid #C7C6C4;
    border-radius: 4px;
    margin-top: 20px;


    &.active-good {
      border: 2px solid $uix-color-success;

      .btn-good {
        color: $uix-color-success;
        rotate: 180;
      }
    }

    &.active-bad {
      border: 2px solid $uix-color-error;

      .btn-bad {
        color: $uix-color-error;
      }
    }

    .logo-svg {
      margin-top: 22px;
    }

    .thumb-toggles {
      border-top: 1px solid #D0CFCC;
      min-height: 52px;
      margin: 0 6px 6px 6px;
      color: #C3C3BF;
      cursor: pointer;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;


        svg {
          height: 28px;
          width: 28px;
          color: inherit;
        }
      }

      .btn-bad > svg{
        margin-top: 4px;
      }

      .btn-good {
        border-right: 1px solid #D0CFCC;

        svg {
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>