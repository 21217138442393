import axios from "@/libraries/axios/axiosConfig";

export default async function (id) {
    return await axios({
        method: "get",
        url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/getQrAndCompaniesByTrailerId/${id}`,
        headers: { 'X-TacLite-AppToken': `${process.env.VUE_APP_TACLITE_APPTOKEN_VALUE}` }
    })
    .then(r => r.data)
    .catch((ex) => {
        let error = ex.toJSON()
        error.isValid = false
        return error
    })
}
