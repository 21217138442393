import { createApp } from "vue"
import { i18n } from "./localization"
import * as bootstrap from "bootstrap"
import { ApmVuePlugin } from "@elastic/apm-rum-vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { name } from "../package.json"
import globalMethods from "./plugins/globalMethods"
import globalConstants from "./plugins/globalConstants"
import localforage from "localforage"
import "./registerServiceWorker"
import auth from "./auth.js"
import axios from "axios"

import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons"
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { faCompress } from "@fortawesome/free-solid-svg-icons"
import { faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons"
import { faExpand } from "@fortawesome/free-solid-svg-icons"
import { faFileContract } from "@fortawesome/free-solid-svg-icons"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons"
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons"
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons"
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons"
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import LoadScript from "vue-plugin-load-script"


library.add(faCamera)
library.add(faCircleCheck)
library.add(faCircleInfo)
library.add(faCircleQuestion)
library.add(faCircleXmark)
library.add(faCompress)
library.add(faDownLeftAndUpRightToCenter)
library.add(faExpand)
library.add(faFileContract)
library.add(faMagnifyingGlass)
library.add(faPhone)
library.add(faRotateLeft)
library.add(faThumbsDown)
library.add(faThumbsUp)
library.add(faTriangleExclamation)
library.add(faTrashCan)
library.add(faUpRightAndDownLeftFromCenter)
library.add(faUser)
library.add(faXmark)

axios.interceptors.request.use(function(config) {
  // Validate that the user is authenticated; If not, route to signin page by invoking the logout method on the auth provider. This ensures all cached items are cleared.
  let user = auth.getUser()
  if (user.authorized === false) {
    auth.logout()
  }

  return config
})

const apmConfig = {
  router,
  config: {
    serviceName: name,
    serverUrl: process.env.VUE_APP_APM_SERVER_URL,
    environment: process.env.VUE_APP_APM_ENV,
    distributedTracingOrigins: [`${process.env.VUE_APP_EXT_API_URL}`, "http://localhost:5001", "https://localhost:5001", "https://tac-api-dev01.freightbox.com", "https://tac-api.freightbox.com", "https://fba-shop-frontend.dev.aks.freightbox.com", "https://fba-shop-frontend.test.aks.freightbox.com"],
    logLevel: process.env.VUE_APP_APM_LOG_LEVEL,
    active: process.env.VUE_APP_APM_ACTIVE,
    propagateTracestate: true
  }, 
  captureErrors: true
}

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "fba-DB"
})

export default createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(bootstrap)
  .use(store)
  .use(router)
  .use(ApmVuePlugin, apmConfig)
  .use(i18n)
  .use(globalMethods)
  .use(globalConstants)
  .use(LoadScript)
  .mount("#app")
