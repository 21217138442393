import { createRouter, createWebHistory } from "vue-router"
import auth from "../auth"
import AdminSignIn from "../views/admin/SignIn.vue"
import Trailer from "../views/admin/Trailer.vue"
import TrailerLookup from "../views/admin/TrailerLookup.vue"
import TrailerRegister from "../views/admin/TrailerRegister.vue"
import Instructions from "../views/admin/Instructions.vue"
import Home from "../views/admin/Home.vue"
import TrailerRemoveQr from "../views/admin/TrailerRemoveQr"
import FreightBoxHome from "../views/admin/FreightBoxHome.vue"
import GeotabScanImei from "../views/geotab/GeotabScanImei.vue"

const routes = [
    {
        path: "/home",
        name: "Home",
        component: Home
    },
    {
        path: "/signin",
        name: "SignIn",
        component: AdminSignIn
    },
    {
        path: "/trailer",
        name: "Trailer",
        component: Trailer
    },
    {
        path: "/trailerlookup",
        name: "TrailerLookup",
        component: TrailerLookup
    },
    {
        path: "/trailerlookupremoveqr",
        name: "TrailerLookupRemoveQr",
        component: TrailerLookup,
        props: { removeQr : true}
    },
    {
        path: "/trailerregister/:side/:trailerId/:qrId?",
        name: "TrailerRegister",
        component: TrailerRegister
    },
    {
        path: "/installation",
        name: "Instructions",
        component: Instructions
    },
    {
        // remove QR view
        path: "/trailerremoveqr",
        name: "TrailerRemoveQr",
        component: TrailerRemoveQr
    },
    {
        path: "/freightboxhome",
        name: "FreightBoxHome",
        component: FreightBoxHome
    },
    {
        path: "/scanimei",
        name: "GeotabScanImei",
        component: GeotabScanImei
    },
    
    // Default Route
    {
        path: "/",
        redirect: to => {
            return "SignIn"
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from) => {
    if(to.name !== "SignIn" && auth.getUser().authorized !== true){
        return { name : "SignIn"}
    }
})

export default router