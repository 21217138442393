<template>
  <div id="offline-overlay">
    <div class="modal">
      <div>
        <font-awesome-icon class="icon" :icon="['fas', 'triangle-exclamation']" />
      </div>
      <div class="title">
        {{ $t("Admin.NoServiceModal.headerText") }}
      </div>
      <div class="body">
        {{ $t("Admin.NoServiceModal.subheaderText") }}
      </div>
      <div>
        <fbxButton 
          class="button" 
          :overrideClass="buttonClass"
          :buttonText="buttonText" 
          @click="checkService"/>
      </div>
    </div>
  </div>
</template>

<script>
import fbxButton from "@/components/fbx-button";

export default {
  components: {
    fbxButton
  },
  data:() => ({
    buttonClass: "btn btn-primary fb-form-button",
    buttonText: null
  }),
  created() {
    this.buttonText = `${this.$t('Admin.NoServiceModal.retryButton')}`;
  },
  methods: {
    async checkService() {
      this.setAdminLoading(true, this.onSetLoadingComplete);
      if (navigator.onLine) {
        await this.testServer();
        this.setAdminLoading(false, this.onSetLoadingComplete);
      } else {
        setTimeout(() => { this.setAdminLoading(false, this.onSetLoadingComplete) }, 1000);
      }
    },
    async testServer() {
      const controller = new AbortController();
      const signal = controller?.signal;
      const controllerAbort = setTimeout(() => { controller?.abort() }, 20000);

      try {
        const response = await fetch(`${process.env.VUE_APP_FBA_SHOP_ROOT_URL}`, { method: "HEAD", mode: "no-cors", signal: signal });
        if (response?.ok || response?.type === "opaque") this.$emit("goOnline");
      } catch (ex) {
        console.warn("Failed to make connection:", ex);
      }

      clearTimeout(controllerAbort);
    },
    onSetLoadingComplete(isLoading) {
        let retryClass = ` ${this.$t('Admin.NoServiceModal.retryButtonActionText')}` 
        this.buttonClass = isLoading === true ?
          this.buttonClass + retryClass : 
          this.buttonClass.replace(retryClass, '');
    },
  }
}
</script>


<style lang="scss" scoped>
#offline-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding-top: 20vh;
  z-index: 2;
  background: #524F4A70;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  height: 225px;
  width: 320px;
  border-radius: $uix-control-border-radius;
  padding: 0 10px;
}

.icon {
  height: 23px;
  padding: 0 0 5px 0;
}

.title {
  font-weight: 700;
}

.body {
  font-weight: 500;
}

.button {
  width: 80%;
  margin: 30px 0 0 0;
}


</style>
