import UAParser from "ua-parser-js"

const globalMethods = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        async getDeviceDetails() {
          let parser = new UAParser()

          let details = {
            location: {
              lat: null,
              lon: null
            },
            device: null,
            os: null,
            browser: null
          }

          const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }

          details.device = parser.getDevice()
          details.os = parser.getOS()
          details.browser = parser.getBrowser()
          
          try {
            let geo = await this.getPosition(options)
            details.location.lat = geo.coords.latitude 
            details.location.lon = geo.coords.longitude
          } catch (e) {
            console.warn(e)
            details.location.lat = 0
            details.location.lon = 0
          } finally {
            return details
          }
        },
        getPosition(options) {
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options)
          })
        },
        setAdminLoading(bool, callback) {
          /* NOTE: This is a temporary method (and store tie) leveraged by the Admin tool.
           *       For expediency sake in delivery we chose to go this route
           *       so as to not have to go and refactor all of the non-admin
           *       screens in the app. At some point we need to implement the
           *       Admin approach to system loading/processing, and merge the
           *       concepts of this method with the original "setLoading" method.
           * */
          this.$store.dispatch("setAdminGlobalLoading", bool)
          if (callback) {
            callback(bool)
          }
        },
        setLoading(bool, callback) {
          this.$store.dispatch("setGlobalLoading", bool)
          if (callback) {
            callback(bool)
          }
        },
        titleCase (value) {
          return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
        }
      }
    })
  }
}

export default globalMethods
