<template>
  <div class="app-container">
    <Header />
    <error-banner />
    <transition name="fade">
      <PageOverlay v-show="$store.state.globalLoading" />
    </transition>
    <transition name="fade">
      <OfflineOverlay v-show="!isOnline" @goOnline="goOnline"/>
    </transition>
    <router-view class="fb-body" />
    <Footer />
    <!-- TODO: Update every button to use fbx-button and the new action classes -->
    <!-- Currently, only the admin pages use this loading indicator -->
    <div :class="{'fb-progress-overlay': true, 'show': $store.state.adminGlobalLoading }"></div>
  </div>
</template>

<script>
import PageOverlay from "@/views/PageOverlay"
import OfflineOverlay from "@/views/OfflineOverlay"
import Footer from "@/components/Footer"
import Header from "@/components/Header"
import ErrorBanner from "@/components/ErrorBanner"

export default {
  components: {
    Header,
    PageOverlay,
    OfflineOverlay,
    ErrorBanner,
    Footer
  },
  data: () => ({
    isOnline: true
  }),
  beforeCreate() {
    this.$store.commit("initializeStore")
  },
  created() {
    window.addEventListener('offline', this.goOffline);
  },
  methods: {
    goOffline() {
      this.isOnline = false;
    },
    goOnline() {
      this.isOnline = true;
    }
  }
}
</script>

<style lang="scss">
html,
body {
  background-color: $uix-shade-alt-one;
}

#app {
  background: rgb(247,246,245);
  background: linear-gradient(0deg, rgba(247,246,245,1) 0%, rgba(253,252,252,1) 100%);
  color: $uix-paragraph-text-color;
  font-family: "Open Sans", Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
}

.fb-body {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 1;
  min-height: 650px;
}

.fade-enter-active {
  animation: fade-in 0.2s;
}

.fade-leave-active {
  animation: fade-in 0.1s reverse;
}

.fb-progress-overlay {
  background-color: rgba($uix-shade-white, 0);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;

  &:hover {
    cursor: default;  
  }

  &.show {
    display: block;
  }
}
</style>
