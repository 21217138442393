import axios from "@/libraries/axios/axiosConfig";

export default async function (id, company, accessToken) {
  return await axios({
    method: "get",
    url: `${process.env.VUE_APP_EXT_API_URL}api/admin/qr/installTrailer/${id}/${company}`,
    headers: { "X-TacLite-AppToken": `${process.env.VUE_APP_TACLITE_APPTOKEN_VALUE}`, Authorization: `Bearer ${accessToken}` },
  })
    .then((r) => r.data)
    .catch((ex) => {
      let error = ex.toJSON()
      error.isValid = false
      return error
    })
}
