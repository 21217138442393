<template>
  <div>
    <div class="btn-group fb-btn-group" :class="{ 'has-error': requiredAlert }" role="group" aria-label="Company Selector">
      <button
        type="button"
        class="btn btn-lg uix-btn-outline-default input-radius fb-group-button"
        :class="{ active: selectedCompany === 'knight' }"
        @click="onTrailerCompanyClicked('knight')">
        <img
          alt="Knight Transportation"
          class="fb-img knight-transportation"
          src="../assets/knight-logo-icon.svg">
      </button>
      <button
        type="button"
        class="btn btn-lg uix-btn-outline-default input-radius fb-group-button"
        :class="{ active: selectedCompany === 'swift' }"
        @click="onTrailerCompanyClicked('swift')">
        <img
          alt="Swift Transportation"
          class="fb-img swift-transportation"
          src="../assets/swift-logo-icon.svg">
      </button>
    </div>
    <p
      v-if="requiredAlert"
      class="fb-btn-group-alert">
      {{ $t("KnightOrSwiftToggle.companyRequiredErrorText") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "KnightOrSwiftToggle",
  components: {},
  props: {},
  emits: ["update:modelValue"],
  data: function () {
    return {
      selectedCompany: null,
      requiredAlert: false,
    }
  },
  created: async function () { },
  methods: {
    onTrailerCompanyClicked(company) {
      this.$emit("update:modelValue", company)
      this.selectedCompany = company
      this.setAlert(false)
    },
    setAlert(alert) {
      if (alert) {
        this.requiredAlert = true
      } else {
        this.requiredAlert = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fb-btn-group-alert {
  color: $uix-color-error;
  font-size: 0.8125rem; // 13px
  font-weight: 600;
  margin: -21px 0 30px;
  position: relative;
}

.has-error {
  border: $uix-control-alert-border-width solid $uix-color-error;
  border-radius: $uix-control-border-radius;
}

.fb-btn-group {
  margin: 0 auto 30px;
  width: 100%;

  .fb-group-button {
    margin: 0;
    padding: 0;

    .fb-img.swift-transportation {
      height: 42px;
      margin: 0;
    }

    .fb-img.knight-transportation {
      height: 40px;
      margin: -3px 0 0;
    }

    &:active,
    &:hover,
    &:focus {
      &,
      &:not(:disabled):not(.disabled) {
        background-color: transparent;
        border-color: $uix-sub-heading-text-color;
      }
    }

    &.active {
      &,
      &:focus,
      &:hover,
      &:not(:disabled):not(.disabled) {
        background: $uix-shade-black;
        background: -webkit-linear-gradient(top left, $uix-shade-black, $uix-sub-heading-text-color);
        background: -moz-linear-gradient(top left, $uix-shade-black, $uix-sub-heading-text-color);
        background: linear-gradient(to bottom right, $uix-shade-black, $uix-sub-heading-text-color);
        border-color: $uix-sub-heading-text-color;
        box-shadow: none !important;

        &::after {
          border: 6px solid transparent;
          border-bottom-width: 0;
          border-top-color: $uix-sub-heading-text-color;
          content: '';
          height: 0;
          left: 50%;
          margin: 1px 0 0 -6px;
          position: absolute;
          top: 100%;
          width: 0;
        }
      }
    }
  }

  > .fb-group-button:not(:first-child),
  > .fb-btn-group:not(:first-child) {
    margin-left: -$btn-border-width;
  }

  > .fb-group-button:not(:last-child):not(.dropdown-toggle),
  > .fb-btn-group:not(:last-child) > .fb-group-button {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  > .fb-group-button:not(:first-child),
  > .fb-btn-group:not(:first-child) > .fb-group-button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}
</style>
