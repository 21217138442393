// General Helpers

export const isNullOrUndefined = function (value) {
    return value === null || value === undefined;
};

export const stopPropagation = (evt) => {
    try {
        if (typeof evt.preventDefault === 'function') {
            evt.preventDefault();
        }
        if (typeof evt.stopImmediatePropagation === 'function') {
            evt.stopImmediatePropagation();
        } else {
            evt.cancelBubble = true;
        }
    }
    catch (exception) {
        // No-op
    }
};

export const formatMobileNumber = function (value) {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? "+1 " : "";
      return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
    }
    return cleaned ?? null;
};

// Trailer Assessment Helpers

export const assessmentHasImagesOrComments = function (assessment, subTypeName) {
    if (isNullOrUndefined(assessment) || isNullOrUndefined(subTypeName)) {
        return false;
    }

    const subType = assessment.subTypes?.find((ele) => ele.subType === subTypeName);

    if (isNullOrUndefined(subType)) {
        return false;
    }

    return subType.documents?.length > 0 || subType.comment?.length > 0;
};

export const ERROR_MESSAGES = {
    CANNOT_COMPLETE_REQUEST : "We are unable to complete the request. Please try again or notify administrator."
}