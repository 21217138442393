<template>
  <div>
    <div class="fb-container body">
      <div class="fb-form">
        <h1
          class="fb-form-heading"
          v-text="($t('Admin.ImeiRegister.headerText'))" />
        <p
          class="fb-form-paragraph"
          v-text="($t('Admin.ImeiRegister.subheaderText'))" />
        <!-- <a @click="startScan">Start Scanner</a> -->
        <div
          id="scannerContainer"
          ref="scannerContainer"
          class="stream uix-scanner" />
        <fbx-input
          :id="'imeiNumber'"
          ref="fbxInput"
          v-model="scannedId"
          type="number"
          inputmode="numeric"
          pattern="\d*"
          :label="$t('Admin.ImeiRegister.imeiPlaceholder')"
          :required="true"
          @contents:cleared="onContentsCleared"
          @keypress="onQrIdKeyPress"
          @change="onQrIdChange" />
        <p
          v-if="message != null"
          class="fb-form-paragraph bottom">
          {{ message }}
        </p>
        <fbx-button
          :override-class="saveButtonClass"
          :button-text="$t('Admin.ImeiRegister.saveButton')"
          @clicked="onSaveClicked" />
        <fbx-button
          :override-class="'btn btn-outline-primary fb-form-button'"
          :button-text="$t('Admin.ImeiRegister.cancelButton')"
          @clicked="onCancelClicked" />
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../auth.js"
import fbxButton from "../../components/fbx-button.vue"
import fbxInput from "../../components/fbx-input.vue"
import Quagga from "quagga"

export default {
    components: {
      fbxButton,
      fbxInput
    },
    data: () => ({
      barcodeReader: null,
      company: null,
      headerText: null,
      subheaderText: null,
      isSaved: false,
      message: null,
      saveButtonClass: "btn btn-primary fb-form-button",
      scannedId: null
    }),
    created() {
        this.setLoading(false)
    },
    mounted() {
      if (this.scannedId) {
          this.$refs.fbxInput.setValue(this.scannedId)
      }

      this.startScan()
    },
    beforeUnmount() {
      Quagga.stop()
    },
    methods: {
      startScan() {
        if (Quagga && Quagga.CameraAccess.getActiveStreamLabel()) {
          Quagga.stop()
        }

        let config = {
          locate: true,
          inputStream: {
            name: "live",
            type: "LiveStream",
            target: document.querySelector("#scannerContainer"),
            constraints: {
              facingMode: "environment"
            }
          },
          decoder: {
            readers: ["code_128_reader"],
            multiple: false
          },
          locator: {
            halfSample: true,
            patchSize: "x-large"
          }
        }

        Quagga.init(config, error =>
        {
          if (error) {
            console.log("ERROR:", error)
            return
          }

          let handleDetectedDebounced = this.debounce(function(data) {
            this.handleDetected(data)
          }, 10)

          Quagga.onDetected(handleDetectedDebounced.bind(this))
          Quagga.start()
        })
      },
      debounce(func, wait, immediate) {
        let timeout
        return function() {
          let context = this, args = arguments
          let later = function() {
            timeout = null
            if (!immediate) func.apply(context, args)
          }
          let callNow = immediate && !timeout
          clearTimeout(timeout)
          timeout = setTimeout(later, wait)
          if (callNow) func.apply(context, args)
        }
      },
      displayError(errorMessage) {
        this.message = errorMessage || "There was an error, Please try again"
        this.isSaved = false
      },
      handleDetected(data) {
        if ((this.scannedId || "") !== "") {
          return
        }
        if (data.codeResult.code.length === 15) {
          this.scannedId = data.codeResult.code
          try {
            this.$refs.fbxInput.setValue(this.scannedId)
          }
          catch (e) {
            document.getElementById("imeiNumber").value = this.scannedId
          }
        }
      },
      validateImei() {
        let imei = (this.scannedId || "")
        const isNumeric = /^[0-9]+$/.test(imei)
        const isMinLength = imei.length >= 15
        return {
          isValid: isNumeric && isMinLength,
          message: imei.length == 0 ? "Required" : (imei.length < 15 ? "Invalid: Must be 15 numbers in length" : "")
        }
      },
      onCancelClicked() {
        this.$router.push({ name: "Home" })
      },
      async onSaveClicked() {
        var validationResult = this.validateImei()
        if (!validationResult.isValid) {
          this.$refs.fbxInput.setAlert("error", validationResult.message)
          return
        }

        try {
          this.setAdminLoading(true, this.onSetLoadingComplete)

          let deviceDetails = await this.getDeviceDetails()
          let user = auth.getUser()

          let response = await this.$store.dispatch("saveImei", {
            accessToken: await auth.getAccessTokenSilent(),
            browser: deviceDetails.browser,
            device: deviceDetails.device,
            imei: this.scannedId,
            location: deviceDetails.location,
            operatingSystem: deviceDetails.os,
            username: user.username
          })

          if (response.isSuccessful) {
            alert(`IMEI ${this.scannedId} saved!`)
            this.$router.push({ name: "Home" })
          } else {
            if (response.message) {
              this.$refs.fbxInput.setAlert("error", response.message)
            } else {
              this.$store.dispatch("setError", ERROR_MESSAGES.CANNOT_COMPLETE_REQUEST)
            }
          }
        }
        catch (e) {
          console.error("Exception:", e)
          this.displayError()
        }

        this.setAdminLoading(false, this.onSetLoadingComplete)
      },
      onSetLoadingComplete(isLoading) {
        let savingClass = ` ${this.$t("Admin.ImeiRegister.saveButtonActionText")}` 
        this.saveButtonClass = isLoading === true ?
                                  this.saveButtonClass + savingClass : 
                                  this.saveButtonClass.replace(savingClass, "")
      }
    }
}
</script>
<style lang="scss">
.uix-scanner > video {
  height: auto;
  width: 100%;
}

.uix-scanner > canvas.drawing, canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

.drawingBuffer {
  display: none;
}
</style>

<style lang="scss" scoped>
.uix-scanner {
  margin: auto;
  /* width: 80vw; */
  max-height: 360px;
  max-width: 750px;
  padding-bottom: 20px;
  height: 65vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0;
}

.btn-outline-primary {
    margin-bottom: 40px;
}

.bottom {
  color: #A71616;
  display: flex-end;
  font-weight: bold;
  width: 100%;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
</style>
