import { createStore } from "vuex"
import queries from "@/queries"
import { isEmpty } from "lodash"
import globalConstants from "../plugins/globalConstants"
import { isNullOrUndefined } from '@/shared/helpers'
import removeQrsByTrailerId from "../queries/removeQrsByTrailerId"

export default createStore({
  state: {
    localStoreKeys: {
      adminUserDetailsKey: "su",
      apiTokenKey: "jwt",
      currentLocale: "loc",
      currentTrailerInfoKey: "tfo",
    },
    apiToken: null,
    adminGlobalLoading: false,
    adminUserDetails: null,
    currentLocale: globalConstants.locale.english,
    currentTrailerInfo: null,
    logosInstalled: null,
    globalLoading: false,
    qidExists: false,
    error: undefined
  },
  mutations: {
    clearUserDetails(state) {
      localStorage.removeItem(state.localStoreKeys.adminUserDetailsKey)
      localStorage.removeItem(state.localStoreKeys.apiTokenKey)
      state.adminUserDetails = {}
      state.apiToken = {}
    },
    clearStore(state) {
      localStorage.removeItem(state.localStoreKeys.adminUserDetailsKey)
      localStorage.removeItem(state.localStoreKeys.apiTokenKey)
      localStorage.removeItem(state.localStoreKeys.currentTrailerInfoKey)
      console.log("Local Storage Cleared")
    },
    clearStoreAndState(state) {
      localStorage.removeItem(state.localStoreKeys.adminUserDetailsKey)
      localStorage.removeItem(state.localStoreKeys.apiTokenKey)
      localStorage.removeItem(state.localStoreKeys.currentTrailerInfoKey)
      state.adminUserDetails = {}
      state.apiToken = {}
      state.currentTrailerInfo = {}
    },
    clearCurrentTrailerInfo(state) {
      localStorage.removeItem(state.localStoreKeys.currentTrailerInfoKey)
      state.logosInstalled = null
    },
    initializeStore(state) {
      // apiToken = {jwt:"", saved:DateTime}
      const apiToken = localStorage.getItem(state.localStoreKeys.apiTokenKey)
      //adminUserDetails = {userid, token, groups[]}
      const adminUserDetails = localStorage.getItem(state.localStoreKeys.adminUserDetailsKey)
      //trailerInfo = {qid, trailerId, trailerCompany, isAttached}
      const currentTrailerInfo = localStorage.getItem(state.localStoreKeys.currentTrailerInfoKey) // Should we expire these? How long can we trust them?
      const currentLocale = localStorage.getItem(state.localStoreKeys.currentLocale)

      if (adminUserDetails) {
        state.adminUserDetails = JSON.parse(adminUserDetails)
      }
      if (apiToken) {
        state.apiToken = JSON.parse(apiToken)
      }
      if (currentTrailerInfo) {
        state.currentTrailerInfo = JSON.parse(currentTrailerInfo)
      }
      if (currentLocale) {
        state.currentLocale = JSON.parse(currentLocale)
      }
    },
    saveAdminUserDetails(state, adminUserDetails) {
      adminUserDetails.saved = new Date()
      localStorage.setItem(state.localStoreKeys.adminUserDetailsKey, JSON.stringify(adminUserDetails))
      state.adminUserDetails = adminUserDetails
    },
    saveApiToken(state, token) {
      let apiToken = {}
      apiToken.token = token
      apiToken.saved = new Date()
      localStorage.setItem(state.localStoreKeys.apiTokenKey, JSON.stringify(apiToken))
      state.apiToken = apiToken
    },
    setAdminGlobalLoading(state, isLoading) {
      state.adminGlobalLoading = isLoading
    },
    setGlobalLoading(state, isLoading) {
      state.GlobalLoading = isLoading
    },
    setQidExists(state, exists) {
      state.qidExists = exists
    },
    setLogosInstalled(state, hasLogos) {
      state.logosInstalled = hasLogos
    },
    saveTrailerInfo(state, trailerInfo) {
      trailerInfo.saved = new Date()
      localStorage.setItem(state.localStoreKeys.currentTrailerInfoKey, JSON.stringify(trailerInfo))
      state.currentTrailerInfo = trailerInfo
    },
    saveCurrentLocale(state, locale) {
      localStorage.setItem(state.localStoreKeys.currentLocale, JSON.stringify(locale))
      state.currentLocale = locale
    },
    setError(state, error) {
        state.error = error;
    }
  },
  actions: {
    //use dispatch to call
    async getUserGroups({ commit, state }, payload) {
      var resp = await queries.getUserGroups(payload)
      var userGroups = resp.value
      ///adminUserDetails = {userid, token, groups[]}
      const adminUserDetails = {
        userid: payload.userId,
        token: payload.token,
        groups: userGroups,
      }
      commit("saveAdminUserDetails", adminUserDetails)
      return userGroups
    },

    async setNewQid({ commit, getters, state }, qid) {
      const prevTrailerInfo = state.currentTrailerInfo
      if (getters.validateUserInfo && prevTrailerInfo?.isAttached && qid !== prevTrailerInfo?.qid) {
        console.warn("We've skipped the previous disconnect")
      }
      const trailerInfo = {
        qid: qid,
        isAttached: null,
        trailerCompany: null,
        trailerId: null,
      }
      this.commit("saveTrailerInfo", trailerInfo)
    },

    //<qid>
    async getTrailerbyQId({ commit, state }, qid) {
      const trailerInfo = await queries.getTrailerbyQrId(qid)
      trailerInfo.qid = qid
      this.commit("saveTrailerInfo", trailerInfo)
      return trailerInfo
    },

    async associateQrToTrailer({ commit, state }, payload) {
      return await queries.associateQrToTrailer(
        payload.qrId,
        payload.trailerId,
        payload.trailerCompany,
        payload.accessToken,
        payload.qrInstallLocation,
        payload.browser,
        payload.location,
        payload.device,
        payload.operatingSystem
      )
    },

    async getInstallTrailer({commit, state}, payload) {
      return await queries.getInstallTrailer(
        payload.trailerId,
        payload.trailerCompany,
        payload.accessToken
      )
    },

    async updateLogosInstalledOnTrailer({commit, state}, payload) {
      return await queries.updateLogosInstalledOnTrailer(
        payload.trailerId,
        payload.trailerCompany,
        payload.logosInstalled,
        payload.browser,
        payload.location,
        payload.device,
        payload.operatingSystem,
        payload.accessToken
      )
    },

    async getShopInstallTrailer({ commit, state }, payload) {
      return await queries.getShopInstallTrailer(payload.id, payload.company)
    },

    //< payload.id  = trailerId>
    async getQrAndCompaniesByTrailerId({ commit, state }, payload) {
      return await queries.getQrAndCompaniesByTrailerId(payload.id)
    },

    async removeQrsByTrailerId({commit, state}, payload) {
      return await queries.removeQrsByTrailerId(
        payload.trailerId,
        payload.trailerCompany,
        payload.qrCodes,
        payload.token,
      )
    },

    async saveImei({commit, state}, payload) {
      return await queries.saveImei(
        payload.imei,
        payload.username,
        payload.accessToken,
        payload.browser,
        payload.location,
        payload.device,
        payload.operatingSystem
      )
    },
    
    setAdminGlobalLoading({ commit, state }, isLoading) {
      commit("setAdminGlobalLoading", isLoading)
    },

    setGlobalLoading({ commit, state }, isLoading) {
      commit("setGlobalLoading", isLoading)
    },

    saveCurrentLocale({ commit, state }, locale) {
      commit("saveCurrentLocale", locale)
    },

    setError({ commit, state }, val) {
      if (!val) {
        commit("setError", null)
      } else {
        commit("setError", val)
      }
    },
  },
  modules: {},
  getters: {
    validateCurrentTrailerInfo: (state) => {
      return state?.currentTrailerInfo && (state?.currentTrailerInfo?.qid || (state?.currentTrailerInfo?.trailerId && state?.currentTrailerInfo?.trailerCompany)) ? true : false
    },
    getCurrentTrailerInfo: (state) => {
      return state?.currentTrailerInfo
    },
    getCurrentTrailerId: (state, getters) => {
      if (!isNullOrUndefined(state?.currentTrailerInfo) && !isEmpty(state.currentTrailerInfo)) {
        return state.currentTrailerInfo.trailerId
      }
      else if (!isNullOrUndefined(getters["lastEvent"])) {
        return getters["lastEvent"].trailerId
      }
      return null;
    },
    getAdminGlobalLoadingStatus: (state) => {
      return state?.adminGlobalLoading
    },
    getGlobalLoadingStatus: (state) => {
      return state?.globalLoading
    },
    getCurrentLocale: (state) => {
      return state?.currentLocale
    },
    getError: (state) => {
      return state?.error
    },
    getLogosInstalled: (state) => {
      return state?.logosInstalled;
    }
  },
})