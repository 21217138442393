import axios from "@/libraries/axios/axiosConfig";

/** Token needed for this endpoint */
export default async function(imei, username, accessToken, browser, location, device, operatingSystem) {
  return await axios({
    method: "post",
    url: `${process.env.VUE_APP_EXT_API_URL}api/admin/imei/registerDevice`,
    headers: { "X-TacLite-AppToken": `${process.env.VUE_APP_TACLITE_APPTOKEN_VALUE}`, Authorization: `Bearer ${accessToken}` },
    data: {
      imei: imei,
      browser: browser,
      location: location,
      device: device,
      operatingSystem: operatingSystem,
      username: username
    }
  }).then(r => r.data)
}