<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">{{$t('Admin.TrailerLookup.headerText')}}</h1>
                <p class="fb-form-paragraph" v-html="$t('Admin.TrailerLookup.subheaderText')"></p>
                <knight-or-swift-toggle
                    v-if="companySelectEnabled == true"
                    v-model="selectedCompany"
                    ref="knightOrSwiftToggle"
                    @update:modelValue="onCompanySelectionChanged" />
                <fbx-input
                    :input-mode="trailerIdInputMode"
                    ref="fbxInput"
                    :label="$t('Admin.TrailerLookup.trailerNumberPlaceholder')"
                    :required="true"
                    @update:model-value="onTrailerIdUpdate"
                    @contents:cleared="onTrailerIdCleared" />
                <div class="toggleWrapper">
                    <input
                        type="checkbox"
                        name="typeToggle"
                        class="mobileToggle"
                        v-model="trailerIdHasLetters"
                        id="typeToggle"
                    />
                    <label for="typeToggle"></label>
                    <p class="text-color">Trailer number contains letters</p>
                </div>
                <fbx-button
                    ref="fbxButton"
                    :overrideClass="continueButtonClass"
                    :button-text="continueButtonText"
                    @clicked="onContinueClicked" />
            </div>
        </div>
    </div>
</template>

<script>
import knightOrSwiftToggle from "@/components/KnightOrSwiftToggle.vue"
import fbxInput from "@/components/fbx-input.vue"
import fbxButton from "@/components/fbx-button.vue"
import globalConstants from '@/plugins/globalConstants'

export default {
    components: {
        knightOrSwiftToggle,
        fbxInput,
        fbxButton
    },
    props: ['removeQr'],
    data: () => ({
        companySelectEnabled: true,
        continueButtonClass: 'btn btn-primary fb-form-button',
        continueButtonText: null,
        selectedCompany: null, 
        trailerId: null,
        trailerIdHasLetters: false
    }),
    created() {
        this.setLoading(false)
        this.setAdminLoading(false, this.onSetLoadingComplete)
        this.$store.commit("clearCurrentTrailerInfo")
        this.continueButtonText = `<span>${this.$t('Admin.TrailerLookup.continueButton')}</span>`
    },
    computed: {
        trailerIdInputMode() {
            return this.trailerIdHasLetters ? "search" : "numeric";
        }
    },
    methods: {
        onCompanySelectionChanged(val) {
            this.selectedCompany = val
        },
        async onContinueClicked() {
            if (!this.validateInputs()) {
                return
            }

            this.setAdminLoading(true, this.onSetLoadingComplete)

            // Query for the entered trailer number
            let result = await this.$store.dispatch("getShopInstallTrailer", {
                company: this.selectedCompany,
                id: this.trailerId
            })
            if (result.isValid === false) {
                if (result.reasonCode === globalConstants.reasonCodes.invalidTrailerType) {
                    this.$refs.fbxInput.setAlert('error', `Trailer Type ${result.trailerType} Invalid!`);
                } else {
                    this.$refs.fbxInput.setAlert('error', 'Unable to lookup trailer');
                }
            } else if (result.isFound === false) {
                this.$refs.fbxInput.setAlert('error', 'Not found in database!');
            } else {
                // Save trailer info to local storage and route user to next step
                let result2 = this.$store.commit("saveTrailerInfo", {
                    company: result.trailerCompany,
                    trailerId: result.trailerId
                })
                // Ensure that at least one QR Code is associated with the trailer; If not, directly route the user to the scan page.
                let qrs = await this.$store.dispatch("getQrAndCompaniesByTrailerId", {
                    id: result.trailerId        
                })
                if (this.removeQr) {
                    if (qrs[result.trailerCompany].length === 0) {
                        this.$refs.fbxInput.setAlert('error', 'No QR Associated')
                        this.setAdminLoading(false, this.onSetLoadingComplete)
                        return
                    }
                    else {
                        this.$router.push({ name: "TrailerRemoveQr"})
                        this.setAdminLoading(false, this.onSetLoadingComplete)
                        return
                    }
                }      
                if (qrs.isValid !== true && qrs[result.trailerCompany].length === 0) {
                    this.setAdminLoading(false, this.onSetLoadingComplete)
                    this.$router.push({ 
                        name: "TrailerRegister",
                        params: {
                            side: "default",
                            trailerId: result.trailerId
                        }
                    })
                }
                else {
                    this.$router.push({ name: "Trailer" })
                }
            }

            this.setAdminLoading(false, this.onSetLoadingComplete)
        },
        onSetLoadingComplete(isLoading) {
            let savingClass = ` ${this.$t('Admin.TrailerLookup.continueButtonActionText')}` 
            this.continueButtonClass = isLoading === true ?
                                     this.continueButtonClass + savingClass : 
                                     this.continueButtonClass.replace(savingClass, '')
        },
        onTrailerIdCleared (evt) {
            this.trailerId = null
        },
        onTrailerIdUpdate(value) {
            this.trailerId = value;
            this.$refs.fbxInput.setValue(value);
        },
        validateInputs() {
            let isValid = true

            if (this.trailerId === null) {
                this.$refs.fbxInput.setAlert('error', 'Required')
                isValid = false
            }
            if (this.selectedCompany === null) {
                this.$refs.knightOrSwiftToggle.setAlert(true)
                isValid = false
            }

            return isValid
        }
    }
}
</script>

<style lang="scss" scoped>
.fb-form-paragraph {
    margin: 0 0 30px;

    > em {
        display: block
    }
}

.uix-control {
    margin-bottom: 1.5rem;
}

.btn-primary {
    margin: 0 0 40px;
}

.toggleWrapper {
    display: flex;
    align-items: center;

    margin-bottom: 4rem;

    & p {
        margin-bottom: 0;
    }

    @media screen and (min-width: 400px) {
        margin-bottom: 2rem;
    }
}

input.mobileToggle {
    opacity: 0;
    position: absolute;

    & + label {
        position: relative;
        display: inline-block;
        user-select: none;
        transition: 0.4s ease;
        height: 30px;
        width: 50px;
        border: 1px solid #e4e4e4;
        border-radius: 60px;
        margin-right: 10px;

        &:before {
            content: '';
            position: absolute;
            display: block;
            transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
            height: 30px;
            width: 51px;
            top: 0;
            left: 0;
            border-radius: 30px;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
                0 4px 0px 0 rgba(0, 0, 0, 0.04),
                0 4px 9px rgba(0, 0, 0, 0.13),
                0 3px 3px rgba(0, 0, 0, 0.05);
            transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
            background: whitesmoke;
            height: 28px;
            width: 28px;
            top: 1px;
            left: 0px;
            border-radius: 60px;
        }
    }

    &:checked {
        & + label {
            &:before {
                background: #febe41;
                transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
            }

            &:after {
                left: 24px;
            }
        }
    }
}

</style>
