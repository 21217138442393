<template>
  <div :class="parentClasses">
    <div class="uix-control-container">
      <div class="uix-control-groups">
        <div class="uix-control-group">
          <input
            :id="id"
            v-model="inputValue"
            class="uix-control-text"
            :class="inputValue || hasAlert ? 'uix-control-text' : 'uix-control-text uix-control-text-center'"
            name="ControlInputText"
            :type="type"
            :inputmode="inputMode || keyboardType"
            :required="required"
            :placeholder="$t(label)"
            :readonly="isReadonly"
            :trim="trim"
            @blur="onBlur"
            @change="onChange"
            @focus="onFocus"
            @keydown="onKeydown"
            @keyup="onKeyup"
            @keypress="onKeypress"
            @input="$emit('update:modelValue', $event.target.value)">
          <label 
            v-if="inputValue || hasAlert"
            class="uix-control-sm-label show">
            {{ $t(labelValue) }} 
            <strong
              v-if="hasAlert"
              :class="errorTextClass">
              {{ $t(errorText) }}
            </strong>
          </label>
          <fbx-input-reset
            :value="inputValue"
            @resetValue="onClear" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseStyleControl from "../styles/baseStyleControl"
import fbxInputReset from "./fbx-input-reset.vue"
import { isNullOrUndefined, stopPropagation } from '@/shared/helpers'
import { clone } from 'lodash';
const alertTypes = BaseStyleControl.alertInputThemes

const DEFAULT_CONTROL_GROUP_CLASSES = ["uix-control-group"]

const TYPES = ["text", "password", "number", "tel"]
const DEFAULT_PARENT_CLASSES = ["uix-control", "uix-text-control"]

export default {
  name: "FbxInput",
  components: { fbxInputReset },
  props: {
    alert: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    model: {
      type: String,
      default: ''
    },
    onBlurEvent: {
        type: Function,
        required: false,
        default: null
    },
    onChangeEvent: {
        type: Function,
        required: false,
        default: null
    },
    onFocusEvent: {
        type: Function,
        required: false,
        default: null
    },
    onKeydownEvent: {
        type: Function,
        required: false,
        default: null
    },
    onKeypressEvent: {
        type: Function,
        required: false,
        default: null
    },
    onKeyupEvent: {
        type: Function,
        required: false,
        default: null
    },
    inputMode: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default() {
          return "text"
        },
      validator(type) {
        if (!TYPES.includes(type)) {
          console.warn(`fbx-input has invalid type - ${type}. valid types are ${TYPES}`)
          return false
        } else {
          return true
        }
      },
    },
    trim: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  emits: ["update:modelValue","contents:cleared", "validationError"],
  setup: () => {},
  data: () => {
    return {
      errorText: "Required",
      errorTextClass: "",
      hasAlert: false,
      hasError: false,
      inputValue: "",
      isReadonly: false,
      labelValue: "",
      parentClasses: DEFAULT_PARENT_CLASSES.join(" "),
      inputClasses: "",
      keyboardType: "text",
    }
  },
  watch: {
    required(newValue) {
      if (newValue == false) {
        //use old value
        this.setAlert(this.alert)
      }
    },
    alert(alertValue) {
      this.setAlert(alertValue)
    },
    inputValue(val) {
      if(this.trim === true){
        this.inputValue = val.trim()
      }
      this.labelValue = val.length > 0 ? this.label : ""
      this.validate(val)
      if (this.type == "tel") {
        this.maskUsNumber()
      }
      this.$emit("update:modelValue", this.inputValue)
    },
  },
  created: async function() {
    if (this.inputMode) {
      this.keyboardType = this.inputMode;
    } else if (this.type === "number"){
      this.keyboardType = "numeric"
    } else if (this.type === "text"){
      this.keyboardType = "text"
    } else if (this.type === "tel"){
      this.keyboardType = "tel"
    } else if (this.type === "password"){
      this.keyboardType = "text"
    } else {
      this.keyboardType = "text"
    }

    // If model is not null, then clone it
    if (this.model) {
      this.inputValue = clone(this.model)
    }
  },
  methods: {
    maskUsNumber() {
      var x = this.inputValue.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.inputValue = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    onBlur: function (evt) {
        if (!isNullOrUndefined(this.onBlurEvent)) {
            stopPropagation(evt);
            this.onBlurEvent.call(this, evt);
            return false;
        }
    },
    onClear: function () {
        this.inputValue = ''
        this.isReadonly = false

        this.$emit('input', this.inputValue)
        this.$emit('contents:cleared', this.inputValue)
    },
    onChange: function (evt) {
        if (!isNullOrUndefined(this.onChangeEvent)) {
            stopPropagation(evt);
            this.onChangeEvent.call(this, evt);
            return false;
        }
    },
    onFocus: function (evt){
        if (!isNullOrUndefined(this.onFocusEvent)) {
            stopPropagation(evt);
            this.onFocusEvent.call(this, evt);
            return false;
        }
    },
    onKeydown: function (evt) {
        if (!isNullOrUndefined(this.onKeydownEvent)) {
            stopPropagation(evt);
            this.onKeydownEvent.call(this, evt);
            return false;
        }
    },
    onKeypress: function (evt) {
        if (!isNullOrUndefined(this.onKeypressEvent)) {
            stopPropagation(evt);
            this.onKeypressEvent.call(this, evt);
            return false;
        }
    },
    onKeyup: function (evt) {
        if (!isNullOrUndefined(this.onKeyupEvent)) {
            stopPropagation(evt);
            this.onKeyupEvent.call(this, evt);
            return false;
        }
    },
    setAlert(alertType, overrideText) {
      if (alertTypes.includes(alertType)) {
        this.hasAlert = true
        this.parentClasses = DEFAULT_PARENT_CLASSES.join(" ") + " " + BaseStyleControl.getInputClassesForTheme(alertType).class
        this.errorText = (overrideText || BaseStyleControl.getInputClassesForTheme(alertType).text)
        this.errorTextClass = BaseStyleControl.getInputClassesForTheme(alertType).textClass
      } else {
        this.hasAlert = false
        this.parentClasses = DEFAULT_PARENT_CLASSES.join(" ")
      }
    },
    setReadonly(isReadonly) {
      this.isReadonly = isReadonly
    },
    setValue(val) {
      this.inputValue = val
    },
    validate(value) {
      if (this.required && typeof value === "string" && (value || '').trim().length === 0) {
        this.$emit("validationError", true)
        this.errorText = "alertText.validation"
      } else if (this.type === "tel") {
        if (value.length != 14) {
          this.$emit("validationError", true)
          this.setAlert(this.alert)
          this.errorText = "alertText.invalidPhoneNumber"
        } else {
          this.$emit("validationError", false)
          this.setAlert(this.alert)
          this.errorText = "alertText.validation"
        }
      } else {
        this.setAlert(this.alert)
        this.$emit("validationError", false)
        this.errorText = "alertText.validation"
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.uix-control.uix-textarea-control {
  min-height: 180px;
  background-color: $uix-shade-white;

  .uix-control-container {
    min-height: 180px;
  }

  .uix-control-group {
    height: auto;
    width: 100%;
    z-index: 0;

    .uix-control-sm-label {
      background-color: $uix-shade-white;
      height: 23px;
      right: 8px;
    }

    .uix-control-text {
      background-color: $uix-shade-white;
      height: auto;
      padding-bottom: 8px;
      padding-top: 22px;
      resize: none;
      scrollbar-3dlight-color: $uix-shade-white;
      scrollbar-arrow-color: $uix-shade-300;
      scrollbar-base-color: $uix-shade-white;
      scrollbar-face-color: $uix-shade-300;
      scrollbar-highlight-color: $uix-shade-white;
      scrollbar-shadow-color: $uix-shade-white;
      scrollbar-track-color: $uix-shade-white;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        background-color: $uix-shade-white;
        cursor: pointer !important; // TODO: This is not working in all browsers. Figure out another solution to provide visual feedback on hover.
        height: 8px;
        width: 8px;
      }

      &::-webkit-scrollbar-corner {
        background-color: $uix-shade-white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $uix-shade-300;

        &:hover {
          background-color: $uix-shade-300;
          border: 0 solid $uix-shade-300;
        }

        &:active {
          background-color: $uix-shade-300;
          border: 0 solid $uix-shade-300;
        }
      }

      &.has-placeholder-offset {
        padding-top: 1rem; // 16px
      }
    }

    // Not spanning full height of control so textarea can be scrolled
    .uix-control-clear {
      bottom: auto;
      min-height: 48px;
    }
  }

  .uix-control-btn {
    justify-content: normal; // vertically align button text to top
  }

  &.disabled {
    .uix-control-group {
      .uix-control-sm-label {
        background-color: $uix-shade-100;
      }

      .uix-control-text {
        scrollbar-3dlight-color: $uix-shade-100;
        scrollbar-base-color: $uix-shade-100;
        scrollbar-highlight-color: $uix-shade-100;
        scrollbar-shadow-color: $uix-shade-100;
        scrollbar-track-color: $uix-shade-100;

        &::-webkit-scrollbar {
          background-color: $uix-shade-100;
          cursor: pointer;
          height: 8px;
          width: 8px;
        }
      }
    }
  }
}

.uix-control-text-center {
  padding: 0px 10px
}
</style>
