<template>
    <div class="fb-body fb-home-body fb-bodytext">
      <div v-if="showCompanyLogo">
        <img class="fb-companylogo-small" :src="companyLogoSource" alt="Company Logo">
      </div>
      <div class="fb-home-welcome">
        <p v-text="$t('Admin.Home.welcome') + ' ' + userName" />
      </div>
      <div class="fb-home-listheader">
        <span>{{ $t("Admin.Home.chooseOption") }}</span>
      </div>
      <ul class="fb-home-listbox">
        <li v-for="item in listItems" :key="item.key" class="fb-home-listitem" @click="listItemClicked(item.route)">
          <img class="fb-home-listicon" :src="item.icon" :alt="item.title">{{ item.title }}
          <div class="fb-home-continueindicator">
            <img class="fb-continue-right" src="../../assets/chevron.svg">
          </div>
          <hr class="fb-listitem-separator">
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import auth from "../../auth.js";
  
  export default {
    data: () => ({
      userName: "",
      showQrRemove: false,
      showQrAdd: true, 
      showRedTag: false,
      showYardCheck: false,
      showKnight: false,
      showSwift: false
    }),
    computed: {
      showCompanyLogo() {
        return this.showKnight || this.showSwift;
      },
      companyLogoSource() {
        return this.showKnight ? require("../../assets/knight-logo-icon.svg") : require("../../assets/swift-logo-icon.svg");
      },
      listItems() {
        return [
          { key: "yardcheck", title: "Yard Check", icon: require("../../assets/ballot.svg"), route: "TrailerLookup", show: this.showYardCheck },
          { key: "redtag", title: "Virtual Red Tag", icon: require("../../assets/solid-times-circle.svg"), route: "TrailerLookupRemoveQr", show: this.showRedTag },
          { key: "qrcodeadd", title: "Install QR Code", icon: require("../../assets/qrcode-solid.svg"), route: "TrailerLookup", show: this.showQrAdd },
          { key: "qrcoderemove", title: "Remove QR Code", icon: require("../../assets/link-off.svg"), route: "TrailerLookupRemoveQr", show: this.showQrRemove },
        ].filter(item => item.show);
      },
    },
    async created() {
      const user = auth.getUser();
      if (user) {
        this.userName = user.name;
        this.showKnight = user.username.includes("@knighttrans") || user.username.includes("@squiretrans");
        this.showSwift = user.username.includes("@swifttrans");
        
        this.showQrRemove = this.hasQrRemoveAccess(user);
        this.showRedTag = !!JSON.parse(process.env.VUE_APP_FBA_SHOP_HOME_SHOW_REDTAG) || false
        this.showQrAdd = !!JSON.parse(process.env.VUE_APP_FBA_SHOP_HOME_SHOW_QRCODE) || true
        this.showYardCeck = !!JSON.parse(process.env.VUE_APP_FBA_SHOP_HOME_SHOW_YARDCHECK) || false
      }
    },
    methods: {
      listItemClicked(route) {
        this.$router.push({ name: route });
      },
      hasQrRemoveAccess(user) {
        const presentRoles = user.idTokenClaims.roles;
        const authorizedRoles = process.env.VUE_APP_REMOVE_QR_ROLES.split(",");
        return presentRoles.some(presentRole => authorizedRoles.includes(presentRole));
      },
    },
  };
  </script>
  