<template>
  <a
    v-show="value"
    value="value"
    class="uix-control-clear show"
    title="clear-value"
    @click="reset()">
    <span>&times;</span>
  </a>
</template>

<script>
export default {
  name: "FbxInputReset",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["resetValue"],
  data: () => {
    return {}
  },
  methods: {
    reset() {
      if (this.value != "") {
        this.$emit("resetValue", "")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.uix-control-clear {
  background-color: transparent !important;
  border: 0 solid transparent !important;
  bottom: 0;
  color: $uix-shade-200;
  display: none;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 1rem 0.875rem 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    border: 0px solid transparent;
    -webkit-box-shadow: none !important; // hiding focus styles
    box-shadow: none !important; // hiding focus styles
  }

  &:hover {
    color: $uix-shade-200;
  }

  .show {
    color: $uix-shade-200;

    &:hover {
      color: $uix-shade-200;
    }
  }
}
</style>
