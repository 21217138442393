<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{this.headerText}}
                </h1>
                <p class="fb-form-paragraph">
                    {{this.subheaderText }}
                </p>
                <div class="stream uix-scanner">
                    <qrcode-stream
                        @decode="onDecode"
                        @init="onInit" />
                </div>
                <fbx-input
                    v-model="scannedId"
                    type="text"
                    ref="fbxInput"
                    :id="'trailerId'"
                    :label="$t('Admin.TrailerRegister.qrCodeNumberPlaceholder')"
                    :required="true"
                    @contents:cleared="onContentsCleared"
                    @keypress="onQrIdKeyPress"
                    @change="onQrIdChange" />
                <br />
                <p class="fb-form-paragraph bottom" v-if="this.message != null">
                    {{message}}
                </p>
                <fbx-button
                    :overrideClass="saveButtonClass"
                    :button-text="saveButtonText"
                    @clicked="onSaveClicked" />
                <fbx-button
                    :overrideClass="'btn btn-outline-primary fb-form-button'"
                    :button-text="$t('Admin.TrailerRegister.cancelButton')"
                    @clicked="onCancelClicked" />
            </div>
        </div>
    </div>
</template>

<script>
import auth from "../../auth.js"
import fbxButton from "../../components/fbx-button.vue"
import fbxInput from "../../components/fbx-input.vue"
import { includes } from "lodash"
import { QrcodeStream } from "vue-qrcode-reader"
import { ERROR_MESSAGES } from '@/shared/helpers';

export default {
    components: {
        fbxButton,
        fbxInput,
        QrcodeStream
    },
    data: () => ({
        company: null,
        headerText: null,
        subheaderText: null,
        isSaved: false,
        message: null,
        qrInstallLocation: null,
        saveButtonClass: 'btn btn-primary fb-form-button',
        saveButtonText: null,
        scannedId: null,
        trailerId: null
    }),
    created() {
        this.setLoading(false)
        let urlSide = this.$route.params.side.toLowerCase()
        this.qrInstallLocation = urlSide
        this.trailerId = this.$route.params.trailerId
        this.scannedId = this.$route.params.qrId
        
        let trailerInfo = this.$store.getters.getCurrentTrailerInfo
        if (!trailerInfo) {
            this.$router.push({ name: "TrailerLookup" })
            return
        }

        this.company = trailerInfo.company
        this.headerText = `${this.titleCase(this.company)} ${this.$t('Admin.TrailerRegister.headerText')} #${this.trailerId}`
        this.saveButtonText = `<span>${this.$t('Admin.TrailerRegister.saveButton')}</span>`;
        this.subheaderText = urlSide === 'default' ? this.$t('Admin.TrailerRegister.scanDefaultQrCodeText') : this.titleCase(urlSide) + ((urlSide === 'back' || urlSide === 'front') ? '' : ' Side')
    },
    mounted() {
        if (this.scannedId) {
            this.$refs.fbxInput.setValue(this.scannedId)
        }
    },
    methods: {
        displayError(errorMessage) {
            this.message = errorMessage || "There was an error, Please try again"
            this.isSaved = false
            this.qrInstallLocation = null
        },
        onCancelClicked() {
            if (this.qrInstallLocation === 'default') {
                this.$router.push({ name: "TrailerLookup" })
            }
            else {
                this.navigateToTrailer()
            }
        },
        onContentsCleared() {
            this.scannedId = ''
        },
        onDecode(decodedString) {
            let qrUrl = new URL(decodedString)
            if (this.validateUrl(qrUrl)) {
                var values = decodedString.split("/")
                this.scannedId = values[values.length - 1]
                this.$refs.fbxInput.setValue(this.scannedId)

                // --------------------------------
                // TEMP: Place a short color throb to alert the user the scanning updated... When we have more time we should definitely come up with a better solution
                let counter = 1
                let intervalHandler = setInterval(() => {
                    let item = document.getElementById('trailerId')
                    if (!item) {
                        clearInterval(InteractionHandler)
                        return
                    }
                    item.style.color = item.style.color !== 'red' ? 'red' : 'black'
                    counter++
                    if (counter === 5) {
                        clearInterval(intervalHandler)
                    }
                }, 500);
                // --------------------------------
            } else {
                this.displayError("Invalid QR, Please scan another")
            }
        },
        onQrIdChange(evt) {
            this.scannedId = evt.target.value
        },
        onQrIdKeyPress(evt) {
            this.scannedId = evt.target.value
        },
        async onSaveClicked() {
            if (!this.validaterInputs()) {
                this.$refs.fbxInput.setAlert('error', 'Required')
                return
            }

            try {
                this.setAdminLoading(true, this.onSetLoadingComplete)

                this.trailerCheck = await this.$store.dispatch("getQrAndCompaniesByTrailerId", {
                    id: this.trailerId
                })
                if (this.trailerCheck.isValid === false) {
                    this.$refs.fbxInput.setAlert('error', 'Unable to save QR Code.')
                    this.setAdminLoading(false, this.onSetLoadingComplete)
                } else if (this.trailerCheck.isFound === false) {
                    this.reasonCode = this.trailerCheck.reasonCode
                    this.displayError("Trailer not found. Please try again.")
                    this.setAdminLoading(false, this.onSetLoadingComplete)
                } else {
                    let trailer = this.trailerCheck[this.company]
                    if (!trailer) {
                        this.reasonCode = this.trailerCheck.reasonCode
                        this.displayError("Trailer not found. Please try again.")
                    }
                    else {
                        let qrInstallLoc = _.find(trailer, { 'qrId': this.scannedId} )
                        if (qrInstallLoc) {
                            // If the same side is being scanned that has the QR code, just navigate the user to the trailer arial page
                            if (qrInstallLoc.qrInstallLocation === this.qrInstallLocation) {
                                this.navigateToTrailer()
                                return
                            }

                            let overwrite = confirm(`This QR Code is already associated to the ${qrInstallLoc.qrInstallLocation} side of this trailer. Do you want to reassociate to this side?`)
                            if (overwrite) {
                                this.postAssociationToApi()
                            } else {
                                this.setAdminLoading(false, this.onSetLoadingComplete)
                            }
                        } else {
                            this.postAssociationToApi()
                        }
                    }
                }
            }
            catch (e) {
                console.error('Exception:', e)
                this.displayError()
                this.setAdminLoading(false, this.onSetLoadingComplete)
            }
        },
        onSetLoadingComplete(isLoading) {
            let savingClass = ` ${this.$t('Admin.TrailerRegister.saveButtonActionText')}` 
            this.saveButtonClass = isLoading === true ?
                                     this.saveButtonClass + savingClass : 
                                     this.saveButtonClass.replace(savingClass, '')
        },
        navigateToTrailer() {
            this.$router.push({ name: "Trailer" })
        },
        async postAssociationToApi() {
            try {
                let deviceDetails = await this.getDeviceDetails()
                let response = await this.$store.dispatch("associateQrToTrailer", {
                    accessToken: await auth.getAccessTokenSilent(),
                    trailerCompany: this.company,
                    qrId: this.scannedId,
                    trailerId: this.trailerId,
                    qrInstallLocation: this.qrInstallLocation,
                    browser: deviceDetails.browser,
                    location: deviceDetails.location,
                    device: deviceDetails.device,
                    operatingSystem: deviceDetails.os,
                })
    
                if (response.isSuccessful) {
                    setTimeout(() => {
                        // Placing a 1/2 second delay to ensure that eveything has updated in the database. We've seen at times the callback processes faster than the database is fully persisted.
                        // We need to find out WHY this isn't updating in time...Just don't have the bandwidth to do that right now.
                        this.navigateToTrailer()
                    }, 500);
                } else {
                    if (response.message) {
                        this.$refs.fbxInput.setAlert('error', response.message)
                    } else {
                        this.$store.dispatch("setError", ERROR_MESSAGES.CANNOT_COMPLETE_REQUEST)
                    }
    
                    this.setAdminLoading(false, this.onSetLoadingComplete)
                }
            } catch(ex) {
                this.setAdminLoading(false, this.onSetLoadingComplete)
                this.$store.dispatch("setError", ERROR_MESSAGES.CANNOT_COMPLETE_REQUEST);
            }
        },
        validaterInputs() {
            if (!this.scannedId) {
                return false
            }
            return true
        },
        validateUrl(qrUrl) {
            return includes(process.env.VUE_APP_VALID_QR_HOSTS.split(','), qrUrl.host)
        }
    }
}
</script>

<style scoped>
.uix-scanner {
  margin: auto;
  /* width: 80vw; */
  max-height: 360px;
  max-width: 750px;
  padding-bottom: 20px;
  height: 65vh;
}

.btn-outline-primary {
    margin-bottom: 40px;
}

.bottom {
  color: #A71616;
  display: flex-end;
  font-weight: bold;
  width: 100%;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
</style>
