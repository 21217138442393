import axios from "@/libraries/axios/axiosConfig";

//POST /users/{id | userPrincipalName}/getMemberGroups
export default async function(payload) {
  const token = payload.token
  const userId = payload.userId
  const postData = {
    //0-TacLiteAdmin  1-ShopTechnicians   
    groupIds: ["6d0ce545-829b-4d99-b10a-7f06bebb9d04", "050bab6c-5e38-433f-b943-7c365101ffab"]
  }
  //   const url = process.env.GRAPH_API_URL + "users/" + userId + "/getMemberGroups"
  const url = "https://graph.microsoft.com/v1.0/users/" + userId + "/checkMemberGroups"
  return await axios({
    method: "post",
    url: url,
    data: postData,
    headers: {
      "X-TacLite-AppToken": `${process.env.VUE_APP_TACLITE_APPTOKEN_VALUE}`,
      Authorization: `Bearer ${token}`
    }
  }).then(r => r.data)
}
