<template>
  <div class="fb-body fb-home-body fb-bodytext">
    <div v-if="showCompanyLogo">
      <img class="fb-companylogo-small" :src="companyLogoSource" alt="Company Logo">
    </div>
    <div class="fb-home-welcome">
      <p v-text="$t('Admin.Home.welcome') + ' ' + userName" />
    </div>
    <div class="fb-home-listheader">
      <span>{{ $t("Admin.Home.chooseOption") }}</span>
    </div>
    <ul class="fb-home-listbox">
      <li v-for="item in listItems" :key="item.key" class="fb-home-listitem" @click="listItemClicked(item.route)">
        <img class="fb-home-listicon" :src="item.icon" :alt="item.title">{{ item.title }}
        <div class="fb-home-continueindicator">
          <img class="fb-continue-right" src="../../assets/chevron.svg">
        </div>
        <hr class="fb-listitem-separator">
      </li>
    </ul>
  </div>
</template>

<script>
import auth from "../../auth.js";

export default {
  data: () => ({
    userName: "",
    showKnight: false,
    showSwift: false,
    showFreightBoxHome: true,
    showGeotabHome: true
  }),
  computed: {
    showCompanyLogo() {
      return this.showKnight || this.showSwift;
    },
    companyLogoSource() {
      return this.showKnight ? require("../../assets/knight-logo-icon.svg") : require("../../assets/swift-logo-icon.svg");
    },
    listItems() {
      return [
        { key: "freightboxhome", title: "FreightBox", icon: require("../../assets/qrcode-solid.svg"), route: "FreightBoxHome", show: this.showFreightBoxHome },
        { key: "geotabhome", title: "Geotab", icon: require("../../assets/barcode-solid.svg"), route: "GeotabScanImei", show: true },
      ].filter(item => item.show);
    },
  },
  async created() {
    const user = auth.getUser();
    if (user) {
      this.userName = user.name;
      this.showKnight = this.shouldShowIcon(user.username, ["@knighttrans", "@squiretrans"]);
      this.showSwift = this.shouldShowIcon(user.username, ["@swifttrans"]);
    }
  },
  methods: {
    listItemClicked(route) {
      this.$router.push({ name: route });
    },
    hasQrRemoveAccess(user) {
      const presentRoles = user.idTokenClaims.roles;
      const authorizedRoles = process.env.VUE_APP_REMOVE_QR_ROLES.split(",");
      return presentRoles.some(presentRole => authorizedRoles.includes(presentRole));
    },
    shouldShowIcon(username, substrings) {
      return substrings.some(substring => username.includes(substring));
    }
  },
};
</script>
